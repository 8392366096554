import cl1Image from '../../assets/images/products/Clothes/men/Men’s Unstitched Cotton Plain Suit FREE… (1).jpeg';
import cl2Image from '../../assets/images/products/Clothes/men/Men’s Unstitched Cotton Plain Suit FREE… (1).jpeg';

const womenData = [
  {
    id: 1,
    name: "Cotton Kurta",
    category: "Cotton",
    type: "2 Piece",
    originalPrice: 1500,
    salePrice: 1200,
    rating: 4.5,
    brand: "Brand A",
    image: cl1Image,
    summary: "A comfortable cotton kurta perfect for summer.",
    stock: 10
  },
  {
    id: 2,
    name: "Silk Dress",
    category: "Silk",
    type: "3 Piece",
    originalPrice: 3000,
    salePrice: 2500,
    rating: 4.8,
    brand: "Brand B",
    image: cl2Image,
    summary: "Elegant silk dress for special occasions.",
    stock: 5
  },
  {
    id: 3,
    name: "Lawn Suit",
    category: "Lawn",
    type: "2 Piece",
    originalPrice: 1800,
    salePrice: 1500,
    rating: 4.3,
    brand: "Brand C",
    image: "path_to_image/lawn_suit.jpg",
    summary: "Lightweight and breathable lawn suit, ideal for hot weather.",
    stock: 8
  },
  {
    id: 4,
    name: "Cotton Silk Shirt",
    category: "Cotton Silk",
    type: "3 Piece",
    originalPrice: 2500,
    salePrice: 2000,
    rating: 4.6,
    brand: "Brand D",
    image: "path_to_image/cotton_silk_shirt.jpg",
    summary: "A stylish cotton silk shirt for a refined look.",
    stock: 12
  },
  {
    id: 5,
    name: "Organza Embroidered Dress",
    category: "Organza",
    type: "3 Piece",
    originalPrice: 5000,
    salePrice: 4500,
    rating: 4.7,
    brand: "Brand E",
    image: "path_to_image/organza_dress.jpg",
    summary: "Elegant organza dress with intricate embroidery.",
    stock: 7
  },
  {
    id: 6,
    name: "Velveta Winter Suit",
    category: "Velveta",
    type: "2 Piece",
    originalPrice: 4000,
    salePrice: 3500,
    rating: 4.4,
    brand: "Brand F",
    image: "path_to_image/velveta_suit.jpg",
    summary: "Warm velveta suit, perfect for winter evenings.",
    stock: 9
  },
  {
    id: 7,
    name: "Khaddar Kurti",
    category: "Khaddar",
    type: "2 Piece",
    originalPrice: 2000,
    salePrice: 1800,
    rating: 4.2,
    brand: "Brand G",
    image: "path_to_image/khaddar_kurti.jpg",
    summary: "Durable khaddar kurti suitable for colder seasons.",
    stock: 15
  },
  {
    id: 8,
    name: "Satin Formal Dress",
    category: "Satin",
    type: "3 Piece",
    originalPrice: 6000,
    salePrice: 5500,
    rating: 4.9,
    brand: "Brand H",
    image: "path_to_image/satin_dress.jpg",
    summary: "Luxurious satin dress for formal occasions.",
    stock: 4
  },
  {
    id: 9,
    name: "Cambric Printed Suit",
    category: "Cambric",
    type: "2 Piece",
    originalPrice: 2200,
    salePrice: 2000,
    rating: 4.1,
    brand: "Brand I",
    image: "path_to_image/cambric_suit.jpg",
    summary: "Casual cambric suit with vibrant prints.",
    stock: 11
  },
  {
    id: 10,
    name: "Linen Winter Dress",
    category: "Linen",
    type: "3 Piece",
    originalPrice: 2800,
    salePrice: 2600,
    rating: 4.5,
    brand: "Brand J",
    image: "path_to_image/linen_dress.jpg",
    summary: "Cozy linen dress to keep you warm in winter.",
    stock: 10
  },
  {
    id: 11,
    name: "Chiffon Party Dress",
    category: "Chiffon",
    type: "3 Piece",
    originalPrice: 4500,
    salePrice: 4000,
    rating: 4.8,
    brand: "Brand K",
    image: "path_to_image/chiffon_party_dress.jpg",
    summary: "Elegant chiffon dress for festive occasions.",
    stock: 6
  },
  {
    id: 12,
    name: "Georgette Floral Dress",
    category: "Georgette",
    type: "2 Piece",
    originalPrice: 3500,
    salePrice: 3000,
    rating: 4.4,
    brand: "Brand L",
    image: "path_to_image/georgette_floral_dress.jpg",
    summary: "Beautiful floral dress in georgette fabric.",
    stock: 9
  },
  {
    id: 13,
    name: "Net Embroidered Gown",
    category: "Net",
    type: "3 Piece",
    originalPrice: 7000,
    salePrice: 6500,
    rating: 4.9,
    brand: "Brand M",
    image: "path_to_image/net_embroidered_gown.jpg",
    summary: "Intricately embroidered net gown for formal events.",
    stock: 3
  },
  {
    id: 14,
    name: "Polyester Casual Suit",
    category: "Polyester",
    type: "2 Piece",
    originalPrice: 1300,
    salePrice: 1100,
    rating: 4.0,
    brand: "Brand N",
    image: "path_to_image/polyester_casual_suit.jpg",
    summary: "Affordable casual suit for everyday wear.",
    stock: 20
  },
  {
    id: 15,
    name: "Wool Winter Coat",
    category: "Wool",
    type: "2 Piece",
    originalPrice: 5000,
    salePrice: 4500,
    rating: 4.6,
    brand: "Brand O",
    image: "path_to_image/wool_winter_coat.jpg",
    summary: "Warm wool coat to keep you cozy during winter.",
    stock: 8
  }
];

export default womenData;
