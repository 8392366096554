import cl1Image from '../assets/images/products/Clothes/men/DSC00789.webp';
import cl2Image from '../assets/images/products/Clothes/men/DSC00851.webp';
const productData = [
    {
        id: 1,
        image: cl1Image,
        productName: "Men's Cotton Unstitched Suit",
        categoryName: "Men",
        subCategoryName: "Unstitched Suits",
        fabricType: "Cotton",
        originalPrice: 3000,
        salePrice: 2500,
        discount: "17%",
        currency: "PKR",
        rating: 4.5,
        summary: "High-quality unstitched cotton fabric, perfect for tailoring.",
        stock: 10,
        clotheType: "2 Piece"
    },
    {
        id: 2,
        image: cl2Image ,
        productName: "Men's Kurta Shalwar",
        categoryName: "Men",
        subCategoryName: "Ready-to-Wear",
        fabricType: "Khaddar",
        originalPrice: 3500,
        salePrice: 3000,
        discount: "14%",
        currency: "PKR",
        rating: 4.7,
        summary: "Comfortable and traditional khaddar kurta shalwar for men.",
        stock: 20,
        clotheType: "2 Piece"
    },

    // Women Category
    {
        id: 3,
        image: "path/to/image.jpg",
        productName: "Women's Cotton Unstitched Suit",
        categoryName: "Women",
        subCategoryName: "Unstitched Suits",
        fabricType: "Cotton",
        originalPrice: 2500,
        salePrice: 2000,
        discount: "20%",
        currency: "PKR",
        rating: 4.6,
        summary: "Soft and durable cotton unstitched suit for women.",
        stock: 15,
        clotheType: "3 Piece"
    },

    // Kids Category - Boys Clothing
    {
        id: 4,
        image: "path/to/image.jpg",
        productName: "Boys Shalwar Kameez",
        categoryName: "Kids",
        subCategoryName: "Boys Clothing",
        originalPrice: 1500,
        salePrice: 1200,
        discount: "20%",
        currency: "PKR",
        rating: 4.3,
        summary: "Traditional boys' shalwar kameez in comfortable fabric, perfect for everyday wear.",
        stock: 20
    },
    {
        id: 5,
        image: "path/to/image.jpg",
        productName: "Boys Kurta Shalwar",
        categoryName: "Kids",
        subCategoryName: "Boys Clothing",
        originalPrice: 1600,
        salePrice: 1300,
        discount: "19%",
        currency: "PKR",
        rating: 4.4,
        summary: "Classic kurta shalwar for boys, ideal for formal and casual occasions.",
        stock: 15
    },
    {
        id: 6,
        image: "path/to/image.jpg",
        productName: "Boys Pant and Shirt",
        categoryName: "Kids",
        subCategoryName: "Boys Clothing",
        originalPrice: 1800,
        salePrice: 1500,
        discount: "17%",
        currency: "PKR",
        rating: 4.5,
        summary: "Smart pant and shirt set for boys, suitable for formal wear.",
        stock: 10
    },
    {
        id: 7,
        image: "path/to/image.jpg",
        productName: "Boys Jeans & T-Shirts",
        categoryName: "Kids",
        subCategoryName: "Boys Clothing",
        originalPrice: 1400,
        salePrice: 1100,
        discount: "21%",
        currency: "PKR",
        rating: 4.6,
        summary: "Trendy jeans and T-shirt combo for boys, perfect for casual outings.",
        stock: 25
    },

    // Food Category
    {
        id: 8,
        image: "path/to/image.jpg",
        productName: "Organic Honey",
        categoryName: "Food",
        subCategoryName: "Honey",
        originalPrice: 1500,
        salePrice: 1300,
        discount: "13%",
        currency: "PKR",
        rating: 4.9,
        summary: "Pure, organic honey sourced from natural hives.",
        stock: 50
    },
    {
        id: 9,
        image: "path/to/image.jpg",
        productName: "Cow Desi Ghee",
        categoryName: "Food",
        subCategoryName: "Desi Ghee",
        originalPrice: 2500,
        salePrice: 2300,
        discount: "8%",
        currency: "PKR",
        rating: 4.8,
        summary: "Premium quality desi ghee from grass-fed cows.",
        stock: 30
    },

    // Health Care Category
    {
        id: 10,
        image: "path/to/image.jpg",
        productName: "Multivitamin Tablets",
        categoryName: "Health Care",
        subCategoryName: "Vitamins",
        originalPrice: 900,
        salePrice: 850,
        discount: "6%",
        currency: "PKR",
        rating: 4.7,
        summary: "Daily multivitamin tablets for overall health.",
        stock: 100
    },
    {
        id: 11,
        image: "path/to/image.jpg",
        productName: "Calcium & Magnesium Supplements",
        categoryName: "Health Care",
        subCategoryName: "Supplements",
        originalPrice: 1200,
        salePrice: 1000,
        discount: "17%",
        currency: "PKR",
        rating: 4.6,
        summary: "Essential calcium and magnesium supplements for bone health.",
        stock: 80
    },

    // Tech Hub Category
    {
        id: 12,
        image: "path/to/image.jpg",
        productName: "Earphones",
        categoryName: "Tech Hub",
        subCategoryName: "Earphones",
        originalPrice: 1500,
        salePrice: 1300,
        discount: "13%",
        currency: "PKR",
        rating: 4.4,
        summary: "High-quality earphones with great sound clarity.",
        stock: 30
    },
    {
        id: 13,
        image: "path/to/image.jpg",
        productName: "Power Bank",
        categoryName: "Tech Hub",
        subCategoryName: "Power Banks",
        originalPrice: 2000,
        salePrice: 1800,
        discount: "10%",
        currency: "PKR",
        rating: 4.8,
        details: "Portable power bank with fast charging capability.",
        stock: 40
    },

    // Additional Items
    {
        id: 14,
        image: "path/to/image.jpg",
        productName: "Men's Leather Wallet",
        categoryName: "Men",
        subCategoryName: "Accessories",
        originalPrice: 2000,
        salePrice: 1500,
        discount: "25%",
        currency: "PKR",
        rating: 4.6,
        summary: "Genuine leather wallet with multiple compartments.",
        stock: 60
    },
    {
        id: 15,
        image: "path/to/image.jpg",
        productName: "Women's Silk Scarf",
        categoryName: "Women",
        subCategoryName: "Accessories",
        originalPrice: 1800,
        salePrice: 1400,
        discount: "22%",
        currency: "PKR",
        rating: 4.8,
        summary: "Elegant silk scarf with vibrant colors.",
        stock: 45
    },
    {
        id: 16,
        image: "path/to/image.jpg",
        productName: "Kids Sneakers",
        categoryName: "Kids",
        subCategoryName: "Footwear",
        originalPrice: 2200,
        salePrice: 1800,
        discount: "18%",
        currency: "PKR",
        rating: 4.5,
        summary: "Comfortable sneakers for kids, perfect for all-day wear.",
        stock: 35
    },
    {
        id: 17,
        image: "path/to/image.jpg",
        productName: "Green Tea Pack",
        categoryName: "Food",
        subCategoryName: "Tea",
        originalPrice: 1000,
        salePrice: 850,
        discount: "15%",
        currency: "PKR",
        rating: 4.7,
        summary: "Organic green tea for a refreshing start to your day.",
        stock: 75
    },
    {
        id: 18,
        image: "path/to/image.jpg",
        productName: "Vitamin C Serum",
        categoryName: "Health Care",
        subCategoryName: "Skin Care",
        originalPrice: 1200,
        salePrice: 1000,
        discount: "17%",
        currency: "PKR",
        rating: 4.8,
        summary: "Brightening Vitamin C serum for glowing skin.",
        stock: 50
    },
    {
        id: 19,
        image: "path/to/image.jpg",
        productName: "Bluetooth Speaker",
        categoryName: "Tech Hub",
        subCategoryName: "Speakers",
        originalPrice: 3000,
        salePrice: 2600,
        discount: "13%",
        currency: "PKR",
        rating: 4.9,
        summary: "Portable Bluetooth speaker with high-quality sound.",
        stock: 25
    },
    {
        id: 20,
        image: "path/to/image.jpg",
        productName: "Wireless Charger",
        categoryName: "Tech Hub",
        subCategoryName: "Chargers",
        originalPrice: 2200,
        salePrice: 1900,
        discount: "14%",
        currency: "PKR",
        rating: 4.6,
        summary: "Convenient wireless charger for fast charging.",
        stock: 35
    }
];

export default productData;
