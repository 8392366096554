import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import mhbLogo from "../assets/images/logo.png";
import { IoIosSearch } from "react-icons/io";
import { FaCartShopping } from 'react-icons/fa6';
import { IoMoon } from "react-icons/io5";
import { MdWbSunny } from "react-icons/md";
import { FaHeart, FaBars, FaTimes } from 'react-icons/fa';
import { RiArrowDropDownLine } from "react-icons/ri";
import useScrollTrigger from '../hooks/useScrollTrigger'; // Import the scroll trigger hook
import '../assets/css/navbar.css';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [isDarkMode, setIsDarkMode] = useState(false);
  const scrollDirection = useScrollTrigger(); // Use the hook here
  const cart = useSelector((state) => state.cart?.cart || []);
  const cartCount = cart.reduce((acc, item) => acc + item.qty, 0);

  const favorites = useSelector((state) => state.favorites.favorites || []);
  const favoriteCount = favorites.length;

  const toggleMenu = () => setIsOpen(!isOpen);
  const handleDropdown = (category) => {
    setActiveDropdown((prev) => (prev === category ? null : category));
  };

  const navigate = useNavigate();
  const handleSearchInput = (e) => setSearchQuery(e.target.value);
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      navigate(`/search?query=${searchQuery}`);
      setSearchQuery('');
    }
  };
  const toggleTheme = () => {
    const newTheme = isDarkMode ? 'light' : 'dark';
    setIsDarkMode(!isDarkMode);
    document.body.classList.toggle('dark-theme', !isDarkMode);
    localStorage.setItem('theme', newTheme);
  };
  const handleSearchReset = () => setSearchQuery('');

  return (
    <>
      <header className={`header-container ${scrollDirection === 'down' ? 'hide' : 'show'}`}>
        <div className="logo">
          <Link to="/"><img src={mhbLogo} alt="MHB Store Logo" /></Link>
        </div>

        <form className="search-bar-container" onSubmit={handleSearchSubmit}>
          <div className="icon">
            <IoIosSearch className={`swap-on ${searchQuery ? 'hidden' : ''}`} />
          </div>
          <label htmlFor="search">
            <input
              type="text"
              id="search"
              placeholder="What are you looking for today?"
              value={searchQuery}
              onChange={handleSearchInput}
              required
              autoComplete="off"
            />
            {searchQuery && (
              <button type="button" className="close-btn" onClick={handleSearchReset}>
                <FaTimes />
              </button>
            )}
          </label>
        </form>

        <div className="right-icons">
          <div className="theme-container">
            <label className="toggle-theme" htmlFor="switch">
              <input id="switch" className="input" type="checkbox"checked={isDarkMode} onChange={toggleTheme} />
              <div className="icon icon--moon">
                <IoMoon />
              </div>
              <div className="icon icon--sun">
                <MdWbSunny />
              </div>
            </label>
          </div>
          <div className="fav-prodect-list-container">
            <Link to="/favourit-list" className="fav-link">
              <FaHeart className="fav-icon" />
              {favoriteCount > 0 && <span className="cart-badge-dot">{favoriteCount}</span>}
              <span>Favourite List</span>
            </Link>
          </div>
          <div className="cart-icon-wrapper">
            <Link to="/cart">
              <FaCartShopping className="icon" />
              {cartCount > 0 && <span className="cart-badge-dot">{cartCount}</span>}
            </Link>
          </div>
          <div className="sign-in-container">
            <Link to="/signIn-signUp" className="signIn-signUp-btn">Sign In</Link>
          </div>
        </div>
      </header>

      <nav className={`navbar-container ${isOpen ? 'active' : ''}`}>
        <div className="hamburger" onClick={toggleMenu}>
          {isOpen ? <FaTimes /> : <FaBars />}
        </div>
        <ul className={`nav-links ${isOpen ? 'active' : ''}`}>
          <li>  <Link to="/">Home </Link> </li>
          <li> <Link to="/clothing/men">Men </Link></li>
          <li>  <Link to="/clothing/women">Women </Link> </li>
          <li>    <Link to="/clothing/kids">Kid </Link></li>
          <li className={`dropdown ${activeDropdown === 'food' ? 'active' : ''}`} onClick={() => handleDropdown('food')}>
            <span>Food <RiArrowDropDownLine className={`dropdown-icon ${activeDropdown === 'food' ? 'rotated' : ''}`} /></span>
            <div className={`dropdown-content ${activeDropdown === 'food' ? 'show' : ''}`}>
              <Link to="/food/honey">Honey</Link>
              <Link to="/food/desi-ghee">Desi Ghee</Link>
              <Link to="/food/dried-fruits-nuts">Dried Fruits & Nuts</Link>
              <Link to="/food/sharbat">Sharbat</Link>
              <Link to="/food/achar-chatni">Achar & Chatni</Link>
              <Link to="/food/edible-oils">Edible & Oils</Link>
              <Link to="/food/herbs-spices">Herbs & Spices</Link>
              <Link to="/food/sweeteners">Sweeteners</Link>
              <Link to="/food/supplements">Supplements</Link>
              <Link to="/food/drinks">Drinks</Link>
            </div>
          </li>
          <li><Link to="/category/health-care">Health Care</Link></li>
          <li><Link to="/category/tech-hub">Tech Hub</Link></li>
        </ul>
      </nav>
    </>
  );
};

export default Navbar;
