import React, { useState } from 'react';
import { auth, googleProvider, facebookProvider } from '../../context/firebaseConfig';
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithPopup,
} from 'firebase/auth';
import { FcGoogle } from "react-icons/fc";
import { FaFacebook, FaEye, FaEyeSlash, FaUser, FaEnvelope, FaLock } from 'react-icons/fa';
import "../../assets/css/signInSignUp.css";
import mhbLogo from "../../assets/images/logo.png";

const SignInSignUp = () => {
  const [isSignUp, setIsSignUp] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [userName, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleSignUp = async (event) => {
    event.preventDefault();
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      alert('User registered successfully!');
    } catch (error) {
      alert(error.message);
    }
  };

  const handleSignIn = async (event) => {
    event.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      alert('Signed in successfully!');
    } catch (error) {
      alert(error.message);
    }
  };

  const handleForgotPassword = async () => {
    try {
      await sendPasswordResetEmail(auth, email);
      alert('Password reset email sent!');
    } catch (error) {
      alert(error.message);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
      alert('Signed in with Google!');
    } catch (error) {
      alert(error.message);
    }
  };

  const handleFacebookSignIn = async () => {
    try {
      await signInWithPopup(auth, facebookProvider);
      alert('Signed in with Facebook!');
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <div className="auth-container">
      <img src={mhbLogo} alt="MHBStore Logo" className="auth-logo" />

      <h2 className='auth-heading'>{isSignUp ? 'Sign Up' : 'Sign In'}</h2>

      {isSignUp ? (
        <form onSubmit={handleSignUp} className="form-signup">
          <div className="auth-form-group">
            <FaUser className="auth-icon" />
            <input
              type="text"
              name="firstName"
              id="firstName"
              placeholder=" "
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
              className="auth-input"
            />
            <label htmlFor="firstName" className="auth-label">
              First Name
            </label>
          </div>
          
          <div className="auth-form-group">
            <FaUser className="auth-icon" />
            <input
              type="text"
              name="lastName"
              id="lastName"
              placeholder=" "
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
              className="auth-input"
            />
            <label htmlFor="lastName" className="auth-label">
              Last Name
            </label>
          </div>
          
          <div className="auth-form-group">
            <FaUser className="auth-icon" />
            <input
              type="text"
              name="userName"
              id="userName"
              placeholder=" "
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              required
              className="auth-input"
            />
            <label htmlFor="userName" className="auth-label">
              Username
            </label>
          </div>
          
          <div className="auth-form-group">
            <FaEnvelope className="auth-icon" />
            <input
              type="email"
              name="email"
              id="email"
              placeholder=" "
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="auth-input"
            />
            <label htmlFor="email" className="auth-label">
              Email
            </label>
          </div>

          <div className="auth-form-group password-field">
            <FaLock className="auth-icon" />
            <input
              type={showPassword ? 'text' : 'password'}
              name="password"
              id="password"
              placeholder=" "
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="auth-input"
            />
            <label htmlFor="password" className="auth-label">
              Password
            </label>
            <span onClick={() => setShowPassword(!showPassword)} className="auth-password-toggle">
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>

          <button type="submit" className='auth-btn-signup'>Sign Up</button>
        </form>
      ) : (
        <form onSubmit={handleSignIn} className="form-signin">
          <div className="auth-form-group">
            <FaEnvelope className="auth-icon" />
            <input
              type="email"
              name="email"
              id="email"
              placeholder=" "
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="auth-input"
            />
            <label htmlFor="email" className="auth-label">
              Email
            </label>
          </div>

          <div className="auth-form-group password-field">
            <FaLock className="auth-icon" />
            <input
              type={showPassword ? 'text' : 'password'}
              name="password"
              id="password"
              placeholder=" "
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="auth-input"
            />
            <label htmlFor="password" className="auth-label">
              Password
            </label>
            <span onClick={() => setShowPassword(!showPassword)} className="auth-password-toggle">
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>

          <button type="submit" className='auth-btn-signin'>Sign In</button>

          <button onClick={handleForgotPassword} className="auth-forgot-password">
            Forgot Password?
          </button>
        </form>
      )}

      <div className="auth-social-buttons">
        <button onClick={handleGoogleSignIn} className='auth-btn-google'>
          <FcGoogle className='google-icon'/> Sign in with Google
        </button>
        <button onClick={handleFacebookSignIn} className='auth-btn-facebook'>
          <FaFacebook  className='fb-icon'/> Sign in with Facebook
        </button>
      </div>

      <p onClick={() => setIsSignUp(!isSignUp)} className="auth-toggle">
        {isSignUp ? 'Already have an account? Sign In' : 'New here? Sign Up'}
      </p>
    </div>
  );
};

export default SignInSignUp;
