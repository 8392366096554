import React, { useState, useEffect } from "react";
import { FaList, FaStar } from "react-icons/fa";
import { MdGridOn, MdFavorite, MdFavoriteBorder } from "react-icons/md";
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from "react-icons/ai";
import { LiaCartPlusSolid } from "react-icons/lia";
import Slider from "react-slick";
import sliderData from "../data/sliderData";
import "../assets/css/test.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../app/actions/actionsCart";
import productData from "../data/productData";
import ratingsData from "../data/ratingsData";
import {
  addToFavorites,
  removeFromFavorites,
} from "../app/reducers/favoritesSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../assets/css/customCategories.css";
import "../assets/css/home.css";

const Home = () => {
  const [layout, setLayout] = useState(4);
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredProducts, setFilteredProducts] = useState(productData);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [sortOrder, setSortOrder] = useState("Price, Low To High");
  const [selectedRating, setSelectedRating] = useState(null);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(100000);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const productsPerPage = 12;
  const favorites = useSelector((state) => state.favorites.favorites);
  const cart = useSelector((state) => state.cart.cart);
  const totalPages = Math.ceil(filteredProducts.length / productsPerPage);

  const categoryCounts = productData.reduce(
    (acc, product) => {
      acc[product.categoryName] = (acc[product.categoryName] || 0) + 1;
      acc["All"] += 1;
      return acc;
    },
    { All: 0 }
  );

  const categories = [
    "All",
    ...new Set(productData.map((product) => product.categoryName)),
  ];

  const displayedProducts =
    selectedCategory === "All"
      ? productData
      : productData.filter(
          (product) => product.categoryName === selectedCategory
        );

  const handleRatingChange = (rating) => {
    setSelectedRating(rating);
  };

  const handleAddToCart = (product) => {
    if (isInCart(product.id)) {
      toast.info(`${product.name} is already in the cart!`);
    } else {
      dispatch(addToCart(product));
      toast.success(`${product.name} added to the cart!`);
    }
  };

  const isFavorite = (productId) =>
    favorites.some((fav) => fav.id === productId);
  const isInCart = (productId) => cart.some((item) => item.id === productId);

  const handleFavoriteToggle = (product) => {
    if (isFavorite(product.id)) {
      dispatch(removeFromFavorites(product));
      toast.info(`${product.name} removed from favorites!`);
    } else {
      dispatch(addToFavorites(product));
      toast.success(`${product.name} added to favorites!`);
    }
  };

  const showMoreProducts = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePriceInputChange = (e, type) => {
    const value = Number(e.target.value);
    if (type === "min") {
      setMinValue(value < maxValue ? value : maxValue - 1);
    } else {
      setMaxValue(value > minValue ? value : minValue + 1);
    }
  };

  const handlePriceRangeChange = (e, type) => {
    const value = Number(e.target.value);
    if (type === "min") {
      setMinValue(value < maxValue ? value : maxValue - 1);
    } else {
      setMaxValue(value > minValue ? value : minValue + 1);
    }
  };

  const renderStars = (rating) => (
    <span className="stars">
      {[...Array(5)].map((_, index) => (
        <FaStar key={index} color={index < rating ? "gold" : "grey"} />
      ))}
    </span>
  );

  useEffect(() => {
    const filtered = productData.filter(
      (product) =>
        (selectedCategories.length === 0 ||
          selectedCategories.includes(product.category)) &&
        product.salePrice >= minValue &&
        product.salePrice <= maxValue &&
        (selectedRating === null ||
          Math.floor(product.rating) === selectedRating)
    );

    const sortedProducts = filtered.sort((a, b) => {
      if (sortOrder === "Most Popular") return b.salePrice - a.salePrice;
      if (sortOrder === "Price, Low To High") return a.salePrice - b.salePrice;
      if (sortOrder === "Price, High To Low ") return b.rating - a.rating;
      return 0;
    });

    setFilteredProducts(sortedProducts);
  }, [selectedCategories, minValue, maxValue, selectedRating, sortOrder]);

  const handleLayoutChange = (value) => {
    setLayout(value === "grid" ? 5 : value === "list" ? "list" : value);
  };

  const toggleSidebar = () => {
    setIsSidebarVisible((prev) => !prev);
  };

  const startIndex = (currentPage - 1) * productsPerPage + 1;
  const endIndex = Math.min(
    currentPage * productsPerPage,
    filteredProducts.length
  );

  return (
    <div>
      <div className="carousel-wrapper-slider">
        <Slider
          dots={true} // Show navigation dots
          infinite={true} // Loop through slides
          speed={500} // Transition speed
          slidesToShow={1} // Number of slides visible at once
          slidesToScroll={1} // Number of slides to scroll at a time
          autoplay={true} // Enable autoplay
          autoplaySpeed={3000} // Autoplay interval
          arrows={true} // Show navigation arrows
        >
          {sliderData.map((slide) => (
            <div key={slide.id} className="carousel-slide">
              <div className="carousel-content">
                <div className="carousel-text">
                  <p className="offer-text">{slide.offerText}</p>
                  <h2 className="main-heading">{slide.title}</h2>
                  <p className="sub-description">{slide.description}</p>
                  <a href={slide.link} className="shop-btn">
                    {slide.buttonText}
                  </a>
                </div>
                <div className="right-image-container">
                  <div className="carousel-image">
                    <img
                      src={slide.image}
                      alt={slide.altText}
                      className="promotion-image"
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <ToastContainer />
      <div className="main-section-container">
        <div className="category-heading-container">
          <h1>Explore Cetgories</h1>
        </div>
        <div className="product-category-sidebar-container">
          {isSidebarVisible && (
            <div className="sidebar-container slide-in">
              <span className="sidebar-toggle" onClick={toggleSidebar}>
                <AiOutlineMenuFold size={24} className="sidebarRight-icon" />
              </span>
              <div className="filter-group">
                <h4>Categories</h4>
                {categories.map((category) => (
                  <label key={category} className="category-label">
                    <input
                      type="radio"
                      name="category"
                      value={category}
                      checked={selectedCategory === category}
                      onChange={() => setSelectedCategory(category)}
                    />
                    {category}
                    <span className="stock-count">
                      {" "}
                      ({categoryCounts[category] || 0})
                    </span>
                  </label>
                ))}
              </div>
              <div className="filter-group">
                <h4>Price Range (PKR)</h4>
                <div className="price-slider">
                  <div className="input-price-container">
                    <span>min</span>
                    <input
                      type="text"
                      value={minValue}
                      onChange={(e) => handlePriceInputChange(e, "min")}
                    />
                    <span>max</span>
                    <input
                      type="text"
                      value={maxValue}
                      onChange={(e) => handlePriceInputChange(e, "max")}
                    />
                  </div>
                </div>
                <div className="price-slider">
                  <label>
                    Min Price: ₨{minValue}
                    <input
                      type="range"
                      min="700"
                      max="10000"
                      value={minValue}
                      onChange={(e) => handlePriceRangeChange(e, "min")}
                    />
                  </label>
                  <label>
                    Max Price: ₨{maxValue}
                    <input
                      type="range"
                      min="700"
                      max="10000"
                      value={maxValue}
                      onChange={(e) => handlePriceRangeChange(e, "max")}
                    />
                  </label>
                </div>
              </div>
              <div className="filter-group">
                <h4>Filter by Rating</h4>
                {(ratingsData || []).map(({ rating, count }) => (
                  <label key={rating} className="rating-option">
                    <input
                      type="radio"
                      value={rating}
                      checked={selectedRating === rating}
                      onChange={() => handleRatingChange(rating)}
                    />
                    <div className="stars">{renderStars(rating)}</div>
                    <span className="rating-count">({count})</span>
                  </label>
                ))}
              </div>
            </div>
          )}

          <div className="product-container-main">
            {!isSidebarVisible && (
              <div className="sidebar-toggle-button" onClick={toggleSidebar}>
                <AiOutlineMenuUnfold size={24} />
              </div>
            )}
            <div className="product-navbar-container">
              <div className="product-filter">
                <div className="product-count-display">
                  <span>
                    Show Results {startIndex}-{endIndex} of{" "}
                    {filteredProducts.length}
                  </span>
                </div>
                <div className="view-options">
                  <span className="view-text">VIEW AS:</span>
                  {[2, 3, 4].map((num) => (
                    <button
                      key={num}
                      className="view-option"
                      onClick={() => handleLayoutChange(num)}
                    >
                      {num}
                    </button>
                  ))}
                  <button
                    className="view-option"
                    onClick={() => handleLayoutChange("grid")}
                  >
                    <MdGridOn />
                  </button>
                  <button
                    className="view-option"
                    onClick={() => handleLayoutChange("list")}
                  >
                    <FaList />
                  </button>
                </div>
                <div className="sort">
                  <span className="sort-text">SORT BY:</span>
                  <select
                    className="select-sort"
                    value={sortOrder}
                    onChange={(e) => setSortOrder(e.target.value)}
                  >
                    <option value="Best Selling">Best Selling</option>
                    <option value="Price, Low To High">
                      Price Low to High
                    </option>
                    <option value="Price, High To Low">
                      Price High to Low
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div
              className={`product-layout ${
                layout === "list" ? "product-list" : "product-grid"
              }`}
            >
              <div className={`products-container grid-${layout}`}>
                {displayedProducts.length > 0 ? (
                  displayedProducts.map((product) => (
                    <div key={product.id} className="product-cart">
                      <span className="sale-tag">SALE {product.discount}</span>
                      <img
                        src={product.image}
                        alt={product.productName}
                        className="list-image"
                      />
                      <div className="product-info">
                        <h5 className="product-name">{product.productName}</h5>
                        <div className="price">
                          <span className="old-price">
                            ₨{product.originalPrice.toFixed(2)}
                          </span>
                          <span className="new-price">
                            ₨{product.salePrice.toFixed(2)}
                          </span>
                        </div>
                      </div>
                      <div className="rating">
                        {[...Array(5)].map((_, i) => (
                          <FaStar
                            key={i}
                            color={
                              i < Math.floor(product.rating)
                                ? "#ffc107"
                                : "#e4e5e9"
                            }
                          />
                        ))}
                      </div>
                      <div className="action-buttons action-list-btn">
                        <span
                          className="quick-add-btn quick-add-btn-list"
                          onClick={() => handleAddToCart(product)}
                        >
                          <LiaCartPlusSolid />
                        </span>
                        <span
                          className="wishlist-icon wishlist-icon-list"
                          onClick={() => handleFavoriteToggle(product)}
                        >
                          {isFavorite(product.id) ? (
                            <MdFavorite style={{ color: "red" }} />
                          ) : (
                            <MdFavoriteBorder />
                          )}
                        </span>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="no-products-message">
                    <h4>
                      No products match the selected filters. Please adjust your
                      filters to see more options.
                    </h4>
                  </div>
                )}
              </div>
            </div>

            {currentPage < totalPages && (
              <div
                className="show_more_btn-wrapper"
                whileHover={{ scale: 1.1 }}
                transition={{ duration: 0.3 }}
              >
                <button className="show_more_btn" onClick={showMoreProducts}>
                  Show More
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
