const hairLossData = [
    {
      id: 1,
      name: 'Biotin Hair Growth Serum - 30ml',
      category: 'Hair Growth Serums',
      originalPrice: 1200,
      salePrice: 950,
      rating: 4.5,
      image: '/images/hairloss/biotin-serum.jpg',
      summary: 'Promotes hair growth with biotin and natural extracts.',
    },
    {
      id: 2,
      name: 'Anti-Hair Loss Shampoo - 250ml',
      category: 'Anti-Hair Loss Shampoos',
      originalPrice: 800,
      salePrice: 600,
      rating: 4.2,
      image: '/images/hairloss/anti-hair-loss-shampoo.jpg',
      summary: 'Prevents hair loss and strengthens roots.',
    },
    {
      id: 3,
      name: 'Collagen & Keratin Supplements',
      category: 'Nutritional Supplements',
      originalPrice: 1500,
      salePrice: 1250,
      rating: 4.6,
      image: '/images/hairloss/collagen-supplements.jpg',
      summary: 'Enhances hair health with collagen and keratin.',
    },
    {
      id: 4,
      name: 'Scalp Treatment Spray',
      category: 'Scalp Treatments',
      originalPrice: 1100,
      salePrice: 900,
      rating: 4.3,
      image: '/images/hairloss/scalp-treatment.jpg',
      summary: 'Improves scalp health and reduces dandruff.',
    },
    {
      id: 5,
      name: 'Minoxidil Solution - 60ml',
      category: 'Hair Growth Serums',
      originalPrice: 1800,
      salePrice: 1500,
      rating: 4.8,
      image: '/images/hairloss/minoxidil.jpg',
      summary: 'Clinically proven formula to combat hair loss.',
    },
    {
      id: 6,
      name: 'Vitamin E & Argan Oil Shampoo',
      category: 'Anti-Hair Loss Shampoos',
      originalPrice: 1000,
      salePrice: 750,
      rating: 4.4,
      image: '/images/hairloss/argan-shampoo.jpg',
      summary: 'Rich in vitamin E and argan oil to nourish scalp.',
    },
    {
      id: 7,
      name: 'Hair Health Vitamins - 60 Capsules',
      category: 'Nutritional Supplements',
      originalPrice: 1300,
      salePrice: 1000,
      rating: 4.5,
      image: '/images/hairloss/hair-vitamins.jpg',
      summary: 'Supports hair growth and reduces breakage.',
    },
    {
      id: 8,
      name: 'Scalp Massaging Serum',
      category: 'Scalp Treatments',
      originalPrice: 900,
      salePrice: 700,
      rating: 4.3,
      image: '/images/hairloss/scalp-massage-serum.jpg',
      summary: 'Soothes scalp and promotes hair thickness.',
    },
    {
      id: 9,
      name: 'Caffeine Infused Hair Growth Serum',
      category: 'Hair Growth Serums',
      originalPrice: 1600,
      salePrice: 1300,
      rating: 4.7,
      image: '/images/hairloss/caffeine-serum.jpg',
      summary: 'Caffeine-infused serum to stimulate hair follicles.',
    },
    {
      id: 10,
      name: 'Anti-Dandruff & Hair Fall Shampoo',
      category: 'Anti-Hair Loss Shampoos',
      originalPrice: 950,
      salePrice: 750,
      rating: 4.2,
      image: '/images/hairloss/anti-dandruff-shampoo.jpg',
      summary: 'Dual-action shampoo for dandruff control and hair loss prevention.',
    },
  ];
  
  export default hairLossData;
  