import React from "react";
import "../assets/css/preloader.css";

const Preloader = () => {
  return (
    <div className="preloader-container">
      <div className="preloader-icon">
        <div className="loader-shopping-cart-icon">🛒</div>
      </div>
      <svg className="pl" width="240" height="240" viewBox="0 0 240 240">
        <circle className="pl__ring pl__ring--a" cx="120" cy="120" r="105" />
        <circle className="pl__ring pl__ring--b" cx="120" cy="120" r="70" />
        <circle className="pl__ring pl__ring--c" cx="120" cy="120" r="35" />
      </svg>
    </div>
  );
};

export default Preloader;
