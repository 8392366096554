const jewelryData = [
    {
      id: 1,
      name: 'Gold-Plated Necklace Set',
      category: 'Imitation Gold Jewelry',
      originalPrice: 1500,
      salePrice: 1200,
      rating: 4.5,
      image: '/images/jewelry/gold-necklace.jpg',
      summary: 'Elegant gold-plated necklace with matching earrings.',
    },
    {
      id: 2,
      name: 'Silver-Plated Bracelet',
      category: 'Silver-Plated Jewelry',
      originalPrice: 800,
      salePrice: 650,
      rating: 4.3,
      image: '/images/jewelry/silver-bracelet.jpg',
      summary: 'Classic silver-plated bracelet for a stylish look.',
    },
    {
      id: 3,
      name: 'Kundan Choker Set',
      category: 'Kundan Jewelry',
      originalPrice: 2000,
      salePrice: 1600,
      rating: 4.7,
      image: '/images/jewelry/kundan-choker.jpg',
      summary: 'Traditional Kundan choker set with intricate details.',
    },
    {
      id: 4,
      name: 'Polki Stud Earrings',
      category: 'Polki Jewelry',
      originalPrice: 1000,
      salePrice: 800,
      rating: 4.4,
      image: '/images/jewelry/polki-earrings.jpg',
      summary: 'Delicate Polki earrings perfect for special occasions.',
    },
    {
      id: 5,
      name: 'Meenakari Pendant Set',
      category: 'Meenakari Jewelry',
      originalPrice: 1200,
      salePrice: 900,
      rating: 4.6,
      image: '/images/jewelry/meenakari-pendant.jpg',
      summary: 'Beautifully crafted Meenakari pendant with earrings.',
    },
    {
      id: 6,
      name: 'Pearl Drop Earrings',
      category: 'Pearl Jewelry (Artificial)',
      originalPrice: 600,
      salePrice: 450,
      rating: 4.2,
      image: '/images/jewelry/pearl-earrings.jpg',
      summary: 'Artificial pearl earrings for a timeless look.',
    },
    {
      id: 7,
      name: 'Terracotta Necklace',
      category: 'Terracotta Jewelry',
      originalPrice: 700,
      salePrice: 550,
      rating: 4.3,
      image: '/images/jewelry/terracotta-necklace.jpg',
      summary: 'Handcrafted terracotta necklace with tribal design.',
    },
    {
      id: 8,
      name: 'Boho Fashion Rings',
      category: 'Fashion Jewelry',
      originalPrice: 500,
      salePrice: 400,
      rating: 4.1,
      image: '/images/jewelry/fashion-rings.jpg',
      summary: 'Set of boho rings perfect for daily wear.',
    },
    {
      id: 9,
      name: 'Leather Pendant Necklace',
      category: 'Leather Jewelry',
      originalPrice: 900,
      salePrice: 750,
      rating: 4.5,
      image: '/images/jewelry/leather-pendant.jpg',
      summary: 'Stylish leather pendant necklace with unique design.',
    },
    {
      id: 10,
      name: 'Wooden Bead Bracelet',
      category: 'Wood Jewelry',
      originalPrice: 550,
      salePrice: 400,
      rating: 4.3,
      image: '/images/jewelry/wood-bracelet.jpg',
      summary: 'Eco-friendly wooden bead bracelet for a natural look.',
    },
  ];
  
  export default jewelryData;
  