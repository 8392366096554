import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  incrementQty,
  decrementQty,
  removeFromCart,
  clearCart,
} from "../app/actions/actionsCart";
import { useNavigate,Link } from "react-router-dom";
import { IoIosRemoveCircleOutline } from "react-icons/io";
import { FaStar, FaMinus, FaPlus, FaBolt } from "react-icons/fa";
import "../assets/css/cart.css";

const Cart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cart = useSelector((state) => state.cart.cart);

  // Calculate totals
  const subtotal = cart.reduce(
    (acc, item) => acc + (item.salePrice || item.price) * (item.qty || 1),
    0
  );
  const tax = subtotal * 0.05;
  const shipping = subtotal > 0 ? 15 : 0;
  const grandTotal = subtotal + tax + shipping;

  // Handlers
  const handleIncrement = (item) => dispatch(incrementQty(item.id));
  const handleDecrement = (item) => {
    if (item.qty > 1) {
      dispatch(decrementQty(item.id));
    }
  };
  const handleRemove = (item) => dispatch(removeFromCart(item.id));

  return (
    <div className="cart-main-container">
      <div className="Prduct-banner-container">
        <div className="banner-content">
          <h1>Cart</h1>
          <p>
            <Link to="/">Home</Link> / Cart
          </p>
        </div>
      </div>
      <div className="cart-sub-container">
        {cart.length > 0 ? (
          <>
            <div className="cart-container">
              {cart.map((item) => (
                <div key={item.id} className="cart-item">
                  <div className="cart-left-side-container">
                    <img
                      src={item.image}
                      alt={item.name}
                      className="cart-item-image"
                    />
                  </div>
                  <div className="cart-right-side-container">
                    <button
                      onClick={() => handleRemove(item)}
                      className="cart-remove-btn"
                      aria-label="Remove item"
                    >
                      <IoIosRemoveCircleOutline size={24} />
                    </button>
                    <h3>{item.name}</h3>
                    <div
                      className="rating"
                      aria-label={`Rating: ${item.rating || 0}`}
                    >
                      {[...Array(5)].map((_, i) => (
                        <FaStar
                          key={i}
                          className="star"
                          color={
                            i < Math.floor(item.rating || 0)
                              ? "#ffc107"
                              : "#e4e5e9"
                          }
                        />
                      ))}
                      <span className="rating-value">
                        {(item.rating ?? 0).toFixed(1)}
                      </span>
                    </div>
                    <div className="quantity-selector">
                      <button
                        onClick={() => handleDecrement(item)}
                        className="quantity-btn"
                        aria-label="Decrease quantity"
                      >
                        <FaMinus />
                      </button>
                      <span className="quantity-value">{item.qty ?? 1}</span>
                      <button
                        onClick={() => handleIncrement(item)}
                        className="quantity-btn"
                        aria-label="Increase quantity"
                      >
                        <FaPlus />
                      </button>
                    </div>
                    <div className="price">
                      {item.originalPrice &&
                      item.originalPrice > item.salePrice ? (
                        <>
                          <span className="old-price">
                            ₨{item.originalPrice.toFixed(2)}
                          </span>
                          <span className="new-price">
                            ₨{item.salePrice.toFixed(2)}
                          </span>
                        </>
                      ) : (
                        <span className="new-price">
                          ₨{item.price.toFixed(2)}
                        </span>
                      )}
                    </div>
                    <p>
                      Total: PKR {(item.salePrice || item.price) * item.qty}
                    </p>
                    <div className="product-summary">
                      <h4>Product Summary</h4>
                      <p>{item.summary}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="cart-summary">
              <p>Subtotal: PKR {subtotal.toFixed(2)}</p>
              <p>Tax (5%): PKR {tax.toFixed(2)}</p>
              <p>Shipping: PKR {shipping.toFixed(2)}</p>
              <h3>Grand Total: PKR {grandTotal.toFixed(2)}</h3>
              <button
                onClick={() => dispatch(clearCart())}
                className="clear-cart"
              >
                Clear Cart
              </button>
              <button
                onClick={() => navigate("/checkout")}
                className="checkout-button"
              >
                <FaBolt /> Checkout
              </button>
            </div>
          </>
        ) : (
          <div className="cart-empty-container">
            <p>Your cart is empty.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Cart;
