import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { removeFromFavorites } from '../app/reducers/favoritesSlice';
import { addToCart } from '../app/actions/actionsCart';
import { IoHeartDislikeCircleOutline } from "react-icons/io5";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaShoppingCart, FaStar, FaBolt, FaPlus, FaMinus } from 'react-icons/fa';
import { MdOutlineCloseFullscreen } from 'react-icons/md';
import { FaCartShopping } from "react-icons/fa6";
import "../assets/css/favlistPage.css";

const FavouriteList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const favorites = useSelector((state) => state.favorites.favorites);
  const cart = useSelector((state) => state.cart?.items || []);
  const [quickViewProduct, setQuickViewProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);

  const incrementQuantity = () => setQuantity((prevQuantity) => prevQuantity + 1);
  const decrementQuantity = () => setQuantity((prevQuantity) => Math.max(1, prevQuantity - 1));

  const handleQuickView = (product) => {
    setQuickViewProduct(product);
    setQuantity(1);
  };
  const closeQuickView = () => setQuickViewProduct(null);

  const isInCart = (productId) => cart.some((item) => item.id === productId);

  const handleBuyNow = (product) => {
    if (!isInCart(product.id)) {
      dispatch(addToCart({ ...product, quantity }));
    }
    navigate('/checkout');
  };

  const handleAddToCart = (product) => {
    if (isInCart(product.id)) {
      toast.info(`${product.name} is already in the cart!`, { toastId: `already-in-cart-${product.id}` });
    } else {
      dispatch(addToCart({ ...product, quantity }));
      toast.success(`${product.name} added to the cart!`, { toastId: `added-to-cart-${product.id}` });
    }
  };

  return (
    <div className="favorites-main-container">
      <div className="Prduct-banner-container">
        <div className="banner-content">
          <h1>Favorite Products</h1>
          <p><Link to="/">Home</Link> / Favorite Products</p>
        </div>
      </div>
      <div className='favorites-container'>
        {favorites.length === 0 ? (
          <div className="fav-empty-container">
            <p>No favorite products yet.</p>
          </div>
        ) : (
          <div className="product-grid-container">
            {favorites.map((product) => (
              <div key={product.id} className="product-card">
                <div className="product-image">
                  {product.image && (
                    <img src={product.image} alt={product.name} className="hover-zoom" />
                  )}
                  <button className="quick-view-btn" onClick={() => handleQuickView(product)}>Quick View</button>
                </div>
                <div className="product-info">
                  <p className="product-name">{product.name}</p>
                  <div className="price">
                    {product.originalPrice && (
                      <span className="old-price">₨{product.originalPrice.toFixed(2)}</span>
                    )}
                    {product.salePrice && (
                      <span className="new-price">₨{product.salePrice.toFixed(2)}</span>
                    )}
                  </div>
                  <div className="rating">
                    {[...Array(5)].map((_, i) => (
                      <FaStar key={i} className="star" color={i < Math.floor(product.rating) ? '#ffc107' : '#e4e5e9'} />
                    ))}
                    <span className="rating-value">{product.rating.toFixed(1)}</span>
                  </div>
                </div>
                <div className="action-buttons">
                  <button className="quick-add-btn" onClick={() => handleAddToCart(product)}>
                    Quick Add <FaCartShopping />
                  </button>
                  <button className="remove-btn" onClick={() => dispatch(removeFromFavorites(product))}>
                    <IoHeartDislikeCircleOutline size={24} />
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}

        {quickViewProduct && (
          <div className="quick-view-modal">
            <span className="close-modal" onClick={closeQuickView}>
              <MdOutlineCloseFullscreen />
            </span>
            <div className="modal-content">
              <div className="product-details-header">
                <h3>Product Details</h3>
              </div>
              <div className="product-view-main-container">
                <div className="modal-left">
                  <img src={quickViewProduct.image} alt={quickViewProduct.name} className="modal-product-image" />
                </div>
                <div className="modal-right">
                  <h2>{quickViewProduct.name}</h2>
                  <div className="quantity-selector">
                    <button onClick={decrementQuantity} className="quantity-btn">
                      <FaMinus />
                    </button>
                    <span className="quantity-value">{quantity}</span>
                    <button onClick={incrementQuantity} className="quantity-btn">
                      <FaPlus />
                    </button>
                  </div>
                  <div className="price">
                    {quickViewProduct.originalPrice && (
                      <span className="old-price">₨{quickViewProduct.originalPrice.toFixed(2)}</span>
                    )}
                    {quickViewProduct.salePrice && (
                      <span className="new-price">₨{quickViewProduct.salePrice.toFixed(2)}</span>
                    )}
                  </div>
                  <div className="cart-buy-btn-container">
                    <button className="modal-add-to-cart-btn" onClick={() => handleAddToCart(quickViewProduct)}>
                      Add To Cart <FaShoppingCart />
                    </button>
                    <button className="modal-buy-btn" onClick={() => handleBuyNow(quickViewProduct)}>
                      Buy Now <FaBolt />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <ToastContainer autoClose={1000} />
    </div>
  );
};

export default FavouriteList;
