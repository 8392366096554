import { clothingImage, foodImage, cosmeticsImage, fashionImage, healthcareImage, techImage, toysImage } from '../assets/images/images';

const sliderData = [
    {
        id: 1,
        title: "Super Value Deals on Clothing",
        offerText: "Clothing Deals",
        description: "Up to 70% off on all clothing items!",
        link: "/category/clothing",
        buttonText: "Shop Clothing",
        image: clothingImage,
        altText: "Clothing Promotion",
    },
    {
        id: 2,
        title: "Save Big on Food Products",
        offerText: "Food Offers",
        description: "Exclusive deals on fresh and packaged foods.",
        link: "/category/food",
        buttonText: "Shop Food",
        image: foodImage,
        altText: "Food Promotion",
    },
    {
        id: 3,
        title: "Top Deals on Cosmetics",
        offerText: "Cosmetics Sale",
        description: "Get the best beauty products at discounted prices.",
        link: "/category/cosmetics",
        buttonText: "Shop Cosmetics",
        image: cosmeticsImage,
        altText: "Cosmetics Promotion",
    },
    {
        id: 4,
        title: "Discover Latest Fashion Trends",
        offerText: "Fashion Extravaganza",
        description: "Stylish outfits at unbeatable prices!",
        link: "/category/fashion",
        buttonText: "Shop Fashion",
        image: fashionImage,
        altText: "Fashion Promotion",
    },
    {
        id: 5,
        title: "Affordable Health Care Essentials",
        offerText: "Health Care",
        description: "Find quality health care products for you and your family.",
        link: "/category/health-care",
        buttonText: "Shop Health Care",
        image: healthcareImage,
        altText: "Health Care Promotion",
    },
    {
        id: 6,
        title: "Latest Gadgets and Tech",
        offerText: "Tech Hub",
        description: "Up to 50% off on all tech accessories and gadgets!",
        link: "/category/tech-hub",
        buttonText: "Shop Tech",
        image: techImage,
        altText: "Tech Promotion",
    },
    {
        id: 7,
        title: "Fun and Educational Toys",
        offerText: "Toy Sale",
        description: "Bring joy with our wide range of toys at amazing prices.",
        link: "/category/toys",
        buttonText: "Shop Toys",
        image: toysImage,
        altText: "Toys Promotion",
    },
];

export default sliderData;
