const drinksData = [
    {
      id: 1,
      name: "Chamomile Herbal Tea",
      category: "Herbal Teas",
      salePrice: 400,
      originalPrice: 500,
      rating: 4.7,
      image: "/path/to/image1.jpg",
      summary: "A soothing herbal tea made with chamomile flowers.",
    },
    {
      id: 2,
      name: "Organic Green Tea",
      category: "Green Teas",
      salePrice: 450,
      originalPrice: 550,
      rating: 4.8,
      image: "/path/to/image2.jpg",
      summary: "Pure organic green tea with a refreshing taste.",
    },
    {
      id: 3,
      name: "English Breakfast Black Tea",
      category: "Black Teas",
      salePrice: 420,
      originalPrice: 520,
      rating: 4.6,
      image: "/path/to/image3.jpg",
      summary: "Classic black tea blend for a perfect morning start.",
    },
    {
      id: 4,
      name: "Whole Bean Espresso",
      category: "Whole Bean Coffee",
      salePrice: 700,
      originalPrice: 850,
      rating: 4.9,
      image: "/path/to/image4.jpg",
      summary: "Rich and smooth espresso beans for a full-bodied brew.",
    },
    {
      id: 5,
      name: "Organic Ground Coffee",
      category: "Ground Coffee",
      salePrice: 600,
      originalPrice: 750,
      rating: 4.8,
      image: "/path/to/image5.jpg",
      summary: "Finely ground organic coffee for an aromatic experience.",
    },
    {
      id: 6,
      name: "Instant Coffee",
      category: "Instant Coffee",
      salePrice: 500,
      originalPrice: 600,
      rating: 4.4,
      image: "/path/to/image6.jpg",
      summary: "Convenient instant coffee for a quick and easy brew.",
    },
    {
      id: 7,
      name: "Cold Brew Coffee",
      category: "Cold Brew Coffee",
      salePrice: 550,
      originalPrice: 650,
      rating: 4.5,
      image: "/path/to/image7.jpg",
      summary: "Smooth and refreshing cold brew coffee, ready to drink.",
    },
    {
      id: 8,
      name: "Mixed Fruit Juice",
      category: "Fruit Juices",
      salePrice: 300,
      originalPrice: 400,
      rating: 4.6,
      image: "/path/to/image8.jpg",
      summary: "A blend of fresh fruit juices for a natural taste.",
    },
    {
      id: 9,
      name: "Almond Nut Milk",
      category: "Nut Milks",
      salePrice: 600,
      originalPrice: 700,
      rating: 4.7,
      image: "/path/to/image9.jpg",
      summary: "Smooth almond milk, a dairy-free alternative.",
    },
    {
      id: 10,
      name: "Pumpkin Seed Milk",
      category: "Seed Milks",
      salePrice: 650,
      originalPrice: 750,
      rating: 4.8,
      image: "/path/to/image10.jpg",
      summary: "A nutrient-rich milk alternative made from pumpkin seeds.",
    },
    {
      id: 11,
      name: "Oat Grain Milk",
      category: "Grain Milks",
      salePrice: 500,
      originalPrice: 600,
      rating: 4.7,
      image: "/path/to/image11.jpg",
      summary: "Creamy oat milk, a dairy-free beverage made from whole oats.",
    },
    {
      id: 12,
      name: "Organic Electrolyte Sports Drink",
      category: "Organic Sports Drinks",
      salePrice: 550,
      originalPrice: 650,
      rating: 4.5,
      image: "/path/to/image12.jpg",
      summary: "A refreshing sports drink with natural electrolytes and flavors.",
    },
  ];
  
  export default drinksData;
  