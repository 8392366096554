const perfumesData = [
    {
      id: 1,
      name: 'Eau de Parfum - Floral Bouquet',
      category: "Women's Fragrances",
      originalPrice: 1200,
      salePrice: 950,
      rating: 4.5,
      image: '/images/perfumes/floral-bouquet.jpg',
      summary: 'A delightful blend of floral notes for a feminine touch.',
    },
    {
      id: 2,
      name: 'Citrus Burst - Eau de Cologne',
      category: "Men's Fragrances",
      originalPrice: 1300,
      salePrice: 1050,
      rating: 4.3,
      image: '/images/perfumes/citrus-burst.jpg',
      summary: 'Refreshing and vibrant, ideal for daily wear.',
    },
    {
      id: 3,
      name: 'Classic Musk',
      category: 'Unisex Fragrances',
      originalPrice: 1400,
      salePrice: 1100,
      rating: 4.6,
      image: '/images/perfumes/classic-musk.jpg',
      summary: 'A timeless musk fragrance suitable for everyone.',
    },
    {
      id: 4,
      name: 'Night Bloom - Eau de Toilette',
      category: "Women's Fragrances",
      originalPrice: 1600,
      salePrice: 1250,
      rating: 4.8,
      image: '/images/perfumes/night-bloom.jpg',
      summary: 'Rich floral fragrance perfect for evening occasions.',
    },
    {
      id: 5,
      name: 'Woody Spice',
      category: "Men's Fragrances",
      originalPrice: 1100,
      salePrice: 900,
      rating: 4.2,
      image: '/images/perfumes/woody-spice.jpg',
      summary: 'Earthy and spicy, crafted for the modern man.',
    },
    {
      id: 6,
      name: 'Oud Essence',
      category: 'Unisex Fragrances',
      originalPrice: 2000,
      salePrice: 1750,
      rating: 4.9,
      image: '/images/perfumes/oud-essence.jpg',
      summary: 'A luxurious oud fragrance with deep, warm notes.',
    },
    {
      id: 7,
      name: 'Jasmine Whisper',
      category: "Women's Fragrances",
      originalPrice: 1400,
      salePrice: 1150,
      rating: 4.4,
      image: '/images/perfumes/jasmine-whisper.jpg',
      summary: 'Soft jasmine notes with a touch of elegance.',
    },
    {
      id: 8,
      name: 'Ocean Breeze',
      category: 'Unisex Fragrances',
      originalPrice: 1300,
      salePrice: 1050,
      rating: 4.5,
      image: '/images/perfumes/ocean-breeze.jpg',
      summary: 'Light and refreshing with hints of sea salt.',
    },
    {
      id: 9,
      name: 'Amber Wood',
      category: "Men's Fragrances",
      originalPrice: 1500,
      salePrice: 1250,
      rating: 4.6,
      image: '/images/perfumes/amber-wood.jpg',
      summary: 'Deep amber notes blended with woody undertones.',
    },
    {
      id: 10,
      name: 'Lavender Fields',
      category: "Women's Fragrances",
      originalPrice: 1100,
      salePrice: 900,
      rating: 4.3,
      image: '/images/perfumes/lavender-fields.jpg',
      summary: 'A calming lavender scent for a peaceful aura.',
    },
  ];
  
  export default perfumesData;
  