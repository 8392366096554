const dryFruitData = [
    {
      id: 1,
      name: "Organic Almonds",
      category: "Almonds",
      salePrice: 1200,
      originalPrice: 1500,
      rating: 4.8,
      image: "/path/to/image1.jpg",
      summary: "High-quality organic almonds, rich in protein and fiber.",
    },
    {
      id: 2,
      name: "Walnuts",
      category: "Walnuts",
      salePrice: 1000,
      originalPrice: 1300,
      rating: 4.7,
      image: "/path/to/image2.jpg",
      summary: "Premium walnuts, packed with omega-3 and antioxidants.",
    },
    {
      id: 3,
      name: "Cashews",
      category: "Cashews",
      salePrice: 950,
      originalPrice: 1200,
      rating: 4.6,
      image: "/path/to/image3.jpg",
      summary: "Delicious and creamy cashews, ideal for snacking and cooking.",
    },
    {
      id: 4,
      name: "Golden Raisins",
      category: "Raisins",
      salePrice: 500,
      originalPrice: 700,
      rating: 4.5,
      image: "/path/to/image4.jpg",
      summary: "Naturally sweet and juicy golden raisins, perfect for desserts.",
    },
    {
      id: 5,
      name: "Medjool Dates",
      category: "Dates",
      salePrice: 800,
      originalPrice: 1000,
      rating: 4.9,
      image: "/path/to/image5.jpg",
      summary: "Fresh Medjool dates, known for their rich taste and texture.",
    },
    {
      id: 6,
      name: "Dried Apricots",
      category: "Dried Apricots",
      salePrice: 650,
      originalPrice: 850,
      rating: 4.4,
      image: "/path/to/image6.jpg",
      summary: "Naturally dried apricots, a good source of iron and vitamins.",
    },
    {
      id: 7,
      name: "Dried Figs",
      category: "Dried Figs",
      salePrice: 900,
      originalPrice: 1100,
      rating: 4.6,
      image: "/path/to/image7.jpg",
      summary: "Sweet dried figs, loaded with nutrients and fiber.",
    },
    {
      id: 8,
      name: "Pistachios",
      category: "Pistachios",
      salePrice: 1300,
      originalPrice: 1600,
      rating: 4.7,
      image: "/path/to/image8.jpg",
      summary: "Crunchy pistachios with a unique flavor, perfect for snacking.",
    },
    {
      id: 9,
      name: "Salted Cashews",
      category: "Cashews",
      salePrice: 1000,
      originalPrice: 1250,
      rating: 4.5,
      image: "/path/to/image9.jpg",
      summary: "Roasted and salted cashews, great for parties and gatherings.",
    },
    {
      id: 10,
      name: "Raw Almonds",
      category: "Almonds",
      salePrice: 1100,
      originalPrice: 1400,
      rating: 4.6,
      image: "/path/to/image10.jpg",
      summary: "Raw almonds with a crunchy texture, perfect for healthy snacking.",
    }
  ];
  
  export default dryFruitData;
  