import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

const useNavigationLoader = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const routesWithLoader = [
      "/", // Home
      "/signIn-signUp",
      "/shop",
      "/cart",

      // Clothes Subcategories
      "/category/clothing",
      "/clothing/women",
      "/clothing/men",
      "/clothing/kids",

      // Food Subcategories
      "/category/food",
      "/food/dried-fruits-nuts",
      "/food/desi-ghee",
      "/food/honey",
      "/food/sharbat",
      "/food/achar-chatni",
      "/food/edible-oils",
      "/food/herbs-spices",
      "/food/sweeteners",
      "/food/supplements",
      "/food/drinks",

      // Cosmetics Subcategories
      "/category/cosmetics",
      "/cosmetics/beauty",
      "/cosmetics/body-care",
      "/cosmetics/perfumes",
      "/cosmetics/men-care",
      "/cosmetics/hair-care",
      "/cosmetics/hair-loss",

      // Fashion Subcategories
      "/category/fashion",
      "/fashion/jewelry",
      "/fashion/gem-stones",
      "/fashion/watches-glasses",

      // HealthCare
      "/category/health-care",

      // TechHub
      "/category/tech-hub",

      // Toys
      "/category/toys",
      // Mhb Store 
      "/about-us",
      "/contact",
      "/delivery",
      "/certifications",
      "/return-policy",
      "/secure-payment",
      "/terms-conditions",
      
    ];

    if (routesWithLoader.includes(location.pathname)) {
      console.log(`Loading started for route: ${location.pathname}`); // Debug log
      setLoading(true);
      const timeoutId = setTimeout(() => {
        setLoading(false);
        console.log(`Loading ended for route: ${location.pathname}`); // Debug log
      }, 500);

      return () => clearTimeout(timeoutId);
    } else {
      setLoading(false);
    }
  }, [location]);

  return loading;
};

export default useNavigationLoader;
