import React from 'react'

const Fashion = () => {
  return (
    <div>
      <h1>Fashion</h1>
    </div>
  )
}

export default Fashion
