import React from "react";
import { Routes, Route } from "react-router-dom";
// Clothing Pages
import Clothing from "../pages/Categories/Clothing";
import WomenClothing from "../pages/Clothing/WomenClothing";
import MenClothing from "../pages/Clothing/MenClothing";
import KidsClothing from "../pages/Clothing/KidsClothing";
// Food Pages
import Food from "../pages/Categories/Food";
import Honey from "../pages/Food/Honey";
import DesiGhee from "../pages/Food/DesiGhee";
import DryFruit from "../pages/Food/DryFruit";
import Sharbat from "../pages/Food/Sharbat";
import AcharChatni from "../pages/Food/AcharChatni";
import EdibleOils from "../pages/Food/EdibleOils";
import HerbsSpices from "../pages/Food/HerbsSpices";
import Sweeteners from "../pages/Food/Sweeteners";
import Supplements from "../pages/Food/Supplements";
import Drinks from "../pages/Food/Drinks";
// Cosmetics Pages
import Cosmetics from "../pages/Categories/Cosmetics";
import Beauty from "../pages/Cosmetics/Beauty";
import BodyCare from "../pages/Cosmetics/BodyCare";
import Perfumes from "../pages/Cosmetics/Perfumes";
import MenCare from "../pages/Cosmetics/MenCare";
import HairCare from "../pages/Cosmetics/HairCare";
import HairLoss from "../pages/Cosmetics/HairLoss";
// Fashion Pages
import Fashion from "../pages/Categories/Fashion";
import Jewelry from "../pages/Fashion/Jewelry";
import GemStones from "../pages/Fashion/GemStones";
import WatchesGlasses from "../pages/Fashion/WatchesGlasses";
// HealthCare Pages
import HealthCare from "../pages/HealthCare/HealthCare";
// TechHub Pages
import TechHub from "../pages/TechHub/TechHub";
// Toys Pages
import Toys from "../pages/Toys/Toys";
// Fallback page for undefined routes
// Mhb Store 
import About from "../pages/MhbInfo/About"
import Contact from "../pages/MhbInfo/Contact"
import Devilvery from "../pages/MhbInfo/Delivery"
import Certifications from "../pages/MhbInfo/Certifications"
import ReturnPolicy from "../pages/MhbInfo/ReturnPolicy"
import SecurePayment  from "../pages/MhbInfo/SecurePayment"
import TermsCondition  from "../pages/MhbInfo/TermsConditions"
import NotFound from "../pages/NotFound";

const AllPages = () => {
    return (
        <Routes>
            {/* Clothing Routes */}
            <Route path="/category/clothing" element={<Clothing />} />
            <Route path="/clothing/women" element={<WomenClothing />} />
            <Route path="/clothing/men" element={<MenClothing />} />
            <Route path="/clothing/kids" element={<KidsClothing />} />
            

            {/* Food Routes */}
            <Route path="/category/food" element={<Food />} />
            <Route path="/food/dried-fruits-nuts" element={<DryFruit />} />
            <Route path="/food/desi-ghee" element={<DesiGhee />} />
            <Route path="/food/honey" element={<Honey />} />
            <Route path="/food/sharbat" element={<Sharbat />} />
            <Route path="/food/achar-chatni" element={<AcharChatni />} />
            <Route path="/food/edible-oils" element={<EdibleOils />} />
            <Route path="/food/herbs-spices" element={<HerbsSpices />} />
            <Route path="/food/sweeteners" element={<Sweeteners />} />
            <Route path="/food/supplements" element={<Supplements />} />
            <Route path="/food/drinks" element={<Drinks />} />

            {/* Cosmetics Routes */}
            <Route path="/category/cosmetics" element={<Cosmetics />} />
            <Route path="/cosmetics/beauty" element={<Beauty />} />
            <Route path="/cosmetics/body-care" element={<BodyCare />} />
            <Route path="/cosmetics/perfumes" element={<Perfumes />} />
            <Route path="/cosmetics/men-care" element={<MenCare />} />
            <Route path="/cosmetics/hair-care" element={<HairCare />} />
            <Route path="/cosmetics/hair-loss" element={<HairLoss />} />

            {/* Fashion Routes */}
            <Route path="/category/fashion" element={<Fashion />} />
            <Route path="/fashion/jewelry" element={<Jewelry />} />
            <Route path="/fashion/gem-stones" element={<GemStones />} />
            <Route path="/fashion/watches-glasses" element={<WatchesGlasses />} />

            {/* HealthCare Route */}
            <Route path="/category/health-care" element={<HealthCare />} />

            {/* TechHub Route */}
            <Route path="/category/tech-hub" element={<TechHub />} />

            {/* Toys Route */}
            <Route path="/category/toys" element={<Toys />} />
            {/* Mhb Store  */}
            <Route path="/about-us" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/delivery" element={<Devilvery />} />
            <Route path="/certifications" element={<Certifications />} />
            <Route path="/return-policy" element={<ReturnPolicy />} />
            <Route path="/secure-payment" element={<SecurePayment />} />
            <Route path="/terms-conditions" element={<TermsCondition />} />
            {/* Fallback Route for undefined paths */}
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
};

export default AllPages;
