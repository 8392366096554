const honeyData = [
  {
    id: 1,
    name: 'Organic Honey - 500g',
    category: 'Organic Honey',
    type: 'Honey',
    salePrice: 1200,
    originalPrice: 1500,
    rating: 4.5,
    stock: 20,
    image: '/path/to/image1.jpg',
    summary: 'Pure organic honey from certified organic farms.',
  },
  {
    id: 2,
    name: 'Organic Honey - 1kg',
    category: 'Organic Honey',
    type: 'Honey',
    salePrice: 2300,
    originalPrice: 2700,
    rating: 4.8,
    stock: 15,
    image: '/path/to/image2.jpg',
    summary: 'High-quality organic honey in a large jar.',
  },
  {
    id: 3,
    name: 'Raw Honey - 500g',
    category: 'Raw Honey',
    type: 'Honey',
    salePrice: 1300,
    originalPrice: 1600,
    rating: 4.6,
    stock: 25,
    image: '/path/to/image3.jpg',
    summary: 'Unprocessed, raw honey with all-natural nutrients.',
  },
  {
    id: 4,
    name: 'Raw Honey - 1kg',
    category: 'Raw Honey',
    type: 'Honey',
    salePrice: 2400,
    originalPrice: 2800,
    rating: 4.7,
    stock: 10,
    image: '/path/to/image4.jpg',
    summary: 'Rich, raw honey in a large size for honey lovers.',
  },
  {
    id: 5,
    name: 'Wildflower Honey - 500g',
    category: 'Wildflower Honey',
    type: 'Honey',
    salePrice: 1100,
    originalPrice: 1400,
    rating: 4.2,
    stock: 30,
    image: '/path/to/image5.jpg',
    summary: 'Honey sourced from wildflowers for a unique flavor.',
  },
  {
    id: 6,
    name: 'Wildflower Honey - 1kg',
    category: 'Wildflower Honey',
    type: 'Honey',
    salePrice: 2100,
    originalPrice: 2500,
    rating: 4.4,
    stock: 18,
    image: '/path/to/image6.jpg',
    summary: 'Large jar of wildflower honey, perfect for families.',
  },
  {
    id: 7,
    name: 'Organic Honey with Ginger - 500g',
    category: 'Organic Honey',
    type: 'Honey',
    salePrice: 1400,
    originalPrice: 1700,
    rating: 4.3,
    stock: 12,
    image: '/path/to/image7.jpg',
    summary: 'Organic honey infused with ginger for added health benefits.',
  },
  {
    id: 8,
    name: 'Raw Honey with Cinnamon - 500g',
    category: 'Raw Honey',
    type: 'Honey',
    salePrice: 1500,
    originalPrice: 1800,
    rating: 4.5,
    stock: 20,
    image: '/path/to/image8.jpg',
    summary: 'Raw honey with cinnamon for a delicious flavor.',
  },
  {
    id: 9,
    name: 'Wildflower Honey with Lavender - 500g',
    category: 'Wildflower Honey',
    type: 'Honey',
    salePrice: 1600,
    originalPrice: 2000,
    rating: 4.1,
    stock: 14,
    image: '/path/to/image9.jpg',
    summary: 'Wildflower honey with a hint of lavender.',
  },
  {
    id: 10,
    name: 'Organic Honey with Turmeric - 500g',
    category: 'Organic Honey',
    type: 'Honey',
    salePrice: 1500,
    originalPrice: 1900,
    rating: 4.6,
    stock: 16,
    image: '/path/to/image10.jpg',
    summary: 'Boost immunity with this organic honey infused with turmeric.',
  },
  {
    id: 11,
    name: 'Raw Honey - Small Jar',
    category: 'Raw Honey',
    type: 'Honey',
    salePrice: 700,
    originalPrice: 900,
    rating: 4.3,
    stock: 40,
    image: '/path/to/image11.jpg',
    summary: 'Perfectly sized raw honey jar for daily use.',
  },
  {
    id: 12,
    name: 'Wildflower Honey - Small Jar',
    category: 'Wildflower Honey',
    type: 'Honey',
    salePrice: 600,
    originalPrice: 800,
    rating: 4.0,
    stock: 35,
    image: '/path/to/image12.jpg',
    summary: 'Small jar of wildflower honey, perfect for on-the-go.',
  },
  {
    id: 13,
    name: 'Organic Honey with Lemon - 500g',
    category: 'Organic Honey',
    type: 'Honey',
    salePrice: 1450,
    originalPrice: 1750,
    rating: 4.4,
    stock: 22,
    image: '/path/to/image13.jpg',
    summary: 'Organic honey with a refreshing hint of lemon.',
  },
  {
    id: 14,
    name: 'Raw Honey with Propolis - 500g',
    category: 'Raw Honey',
    type: 'Honey',
    salePrice: 1550,
    originalPrice: 1850,
    rating: 4.5,
    stock: 17,
    image: '/path/to/image14.jpg',
    summary: 'Raw honey enriched with propolis for added health benefits.',
  },
  {
    id: 15,
    name: 'Wildflower Honey with Rose - 500g',
    category: 'Wildflower Honey',
    type: 'Honey',
    salePrice: 1650,
    originalPrice: 2100,
    rating: 4.1,
    stock: 10,
    image: '/path/to/image15.jpg',
    summary: 'Unique wildflower honey with a touch of rose essence.',
  },
  {
    id: 16,
    name: 'Organic Honey - Gift Pack (3x250g)',
    category: 'Organic Honey',
    type: 'Honey',
    salePrice: 2700,
    originalPrice: 3200,
    rating: 4.8,
    stock: 8,
    image: '/path/to/image16.jpg',
    summary: 'Gift pack with three flavors of organic honey, perfect for gifting.',
  },
];

export default honeyData;
