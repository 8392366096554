// sharbatData.js
const sharbatData = [
  {
    id: 1,
    name: "Sharbat e Badam",
    category: "Sharbat e Badam",
    brand: "Brand A",
    image: "/assets/images/sharbat/sharbat-badam.jpg",
    originalPrice: 1200,
    salePrice: 1000,
    discount: "20%",
    rating: 4.5,
    stock: 25,
  },
  {
    id: 2,
    name: "Anjubar Sharbat",
    category: "Anjubar",
    brand: "Brand B",
    image: "/assets/images/sharbat/anjubar-sharbat.jpg",
    originalPrice: 1500,
    salePrice: 1300,
    discount: "15%",
    rating: 4,
    stock: 18,
  },
  {
    id: 3,
    name: "Sharbat e Bazori",
    category: "Bazori",
    brand: "Brand C",
    image: "/assets/images/sharbat/bazori-sharbat.jpg",
    originalPrice: 2000,
    salePrice: 1800,
    discount: "10%",
    rating: 4.8,
    stock: 10,
  },
  {
    id: 4,
    name: "Sharbat e Sandal",
    category: "Sandal",
    brand: "Brand D",
    image: "/assets/images/sharbat/sandal-sharbat.jpg",
    originalPrice: 1800,
    salePrice: 1600,
    discount: "11%",
    rating: 3.7,
    stock: 12,
  },
  {
    id: 5,
    name: "Sharbat e Elaichi",
    category: "Elaichi",
    brand: "Brand E",
    image: "/assets/images/sharbat/elaichi-sharbat.jpg",
    originalPrice: 1400,
    salePrice: 1200,
    discount: "14%",
    rating: 4.2,
    stock: 20,
  },
  {
    id: 6,
    name: "Sharbat e Gulab",
    category: "Gulab",
    brand: "Brand F",
    image: "/assets/images/sharbat/gulab-sharbat.jpg",
    originalPrice: 1100,
    salePrice: 900,
    discount: "18%",
    rating: 4.6,
    stock: 30,
  },
  {
    id: 7,
    name: "Sharbat e Falsa",
    category: "Falsa",
    brand: "Brand G",
    image: "/assets/images/sharbat/falsa-sharbat.jpg",
    originalPrice: 1300,
    salePrice: 1100,
    discount: "15%",
    rating: 4.3,
    stock: 22,
  },
  {
    id: 8,
    name: "Sharbat e Zeera",
    category: "Zeera",
    brand: "Brand H",
    image: "/assets/images/sharbat/zeera-sharbat.jpg",
    originalPrice: 900,
    salePrice: 750,
    discount: "17%",
    rating: 3.9,
    stock: 16,
  },
  {
    id: 9,
    name: "Sharbat e Mango",
    category: "Mango",
    brand: "Brand I",
    image: "/assets/images/sharbat/mango-sharbat.jpg",
    originalPrice: 1600,
    salePrice: 1400,
    discount: "13%",
    rating: 4.4,
    stock: 15,
  },
  {
    id: 10,
    name: "Sharbat e Lemon",
    category: "Lemon",
    brand: "Brand J",
    image: "/assets/images/sharbat/lemon-sharbat.jpg",
    originalPrice: 1000,
    salePrice: 800,
    discount: "20%",
    rating: 4.1,
    stock: 18,
  },
];

export default sharbatData;
