// src/data/Clothes/kidsData.js
const kidsData = [
  {
      id: 1,
      name: "Kids Cotton T-Shirt",
      category: "Cotton",
      brand: "Brand A",
      originalPrice: 1200,
      salePrice: 1000,
      rating: 4.5,
      discount: "20%",
      image: "/images/kids-cotton-tshirt.jpg",
      stock: 15,
  },
  {
      id: 2,
      name: "Kids Denim Jeans",
      category: "Denim",
      brand: "Brand B",
      originalPrice: 2500,
      salePrice: 2000,
      rating: 4,
      discount: "20%",
      image: "/images/kids-denim-jeans.jpg",
      stock: 20,
  },
  {
      id: 3,
      name: "Kids Hoodie",
      category: "Winter Wear",
      brand: "Brand C",
      originalPrice: 3000,
      salePrice: 2700,
      rating: 4.8,
      discount: "10%",
      image: "/images/kids-hoodie.jpg",
      stock: 10,
  },
  {
      id: 4,
      name: "Kids Summer Dress",
      category: "Summer Wear",
      brand: "Brand D",
      originalPrice: 1800,
      salePrice: 1500,
      rating: 4.3,
      discount: "17%",
      image: "/images/kids-summer-dress.jpg",
      stock: 25,
  },
  {
      id: 5,
      name: "Kids Track Pants",
      category: "Casual",
      brand: "Brand E",
      originalPrice: 2200,
      salePrice: 1900,
      rating: 4.6,
      discount: "14%",
      image: "/images/kids-track-pants.jpg",
      stock: 30,
  },
  {
      id: 6,
      name: "Kids Puffer Jacket",
      category: "Winter Wear",
      brand: "Brand F",
      originalPrice: 5000,
      salePrice: 4500,
      rating: 4.9,
      discount: "10%",
      image: "/images/kids-puffer-jacket.jpg",
      stock: 8,
  },
  {
      id: 7,
      name: "Kids Swim Shorts",
      category: "Summer Wear",
      brand: "Brand G",
      originalPrice: 1400,
      salePrice: 1200,
      rating: 4.1,
      discount: "14%",
      image: "/images/kids-swim-shorts.jpg",
      stock: 18,
  },
  {
      id: 8,
      name: "Kids Polo Shirt",
      category: "Casual",
      brand: "Brand H",
      originalPrice: 1600,
      salePrice: 1400,
      rating: 4.4,
      discount: "12%",
      image: "/images/kids-polo-shirt.jpg",
      stock: 22,
  },
  {
      id: 9,
      name: "Kids Cotton Shorts",
      category: "Cotton",
      brand: "Brand I",
      originalPrice: 1100,
      salePrice: 900,
      rating: 4.2,
      discount: "18%",
      image: "/images/kids-cotton-shorts.jpg",
      stock: 25,
  },
  {
      id: 10,
      name: "Kids Cardigan Sweater",
      category: "Winter Wear",
      brand: "Brand J",
      originalPrice: 3500,
      salePrice: 3000,
      rating: 4.7,
      discount: "14%",
      image: "/images/kids-cardigan.jpg",
      stock: 12,
  },
  {
      id: 11,
      name: "Kids Dress Shoes",
      category: "Formal",
      brand: "Brand K",
      originalPrice: 2800,
      salePrice: 2500,
      rating: 4.5,
      discount: "11%",
      image: "/images/kids-dress-shoes.jpg",
      stock: 14,
  },
  {
      id: 12,
      name: "Kids Casual Sneakers",
      category: "Casual",
      brand: "Brand L",
      originalPrice: 2000,
      salePrice: 1800,
      rating: 4.3,
      discount: "10%",
      image: "/images/kids-casual-sneakers.jpg",
      stock: 18,
  },
  {
      id: 13,
      name: "Kids Fleece Joggers",
      category: "Winter Wear",
      brand: "Brand M",
      originalPrice: 2100,
      salePrice: 1800,
      rating: 4.4,
      discount: "14%",
      image: "/images/kids-fleece-joggers.jpg",
      stock: 10,
  },
  {
      id: 14,
      name: "Kids Party Dress",
      category: "Formal",
      brand: "Brand N",
      originalPrice: 3200,
      salePrice: 2800,
      rating: 4.6,
      discount: "13%",
      image: "/images/kids-party-dress.jpg",
      stock: 5,
  },
  {
      id: 15,
      name: "Kids Khaki Pants",
      category: "Casual",
      brand: "Brand O",
      originalPrice: 2300,
      salePrice: 2000,
      rating: 4.3,
      discount: "13%",
      image: "/images/kids-khaki-pants.jpg",
      stock: 20,
  },
  {
      id: 16,
      name: "Kids Graphic T-Shirt",
      category: "Cotton",
      brand: "Brand P",
      originalPrice: 1500,
      salePrice: 1300,
      rating: 4.5,
      discount: "13%",
      image: "/images/kids-graphic-tshirt.jpg",
      stock: 30,
  },
];

export default kidsData;
