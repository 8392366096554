const herbsSpicesData = [
    {
      id: 1,
      name: "Turmeric Powder",
      category: "Turmeric",
      salePrice: 300,
      originalPrice: 400,
      rating: 4.7,
      image: "/path/to/image1.jpg",
      summary: "Pure turmeric powder, known for its vibrant color and health benefits.",
    },
    {
      id: 2,
      name: "Whole Coriander Seeds",
      category: "Coriander Seeds",
      salePrice: 250,
      originalPrice: 350,
      rating: 4.5,
      image: "/path/to/image2.jpg",
      summary: "Aromatic coriander seeds, ideal for flavoring a variety of dishes.",
    },
    {
      id: 3,
      name: "Dried Rosemary",
      category: "Rosemary",
      salePrice: 400,
      originalPrice: 500,
      rating: 4.6,
      image: "/path/to/image3.jpg",
      summary: "Premium dried rosemary, perfect for seasoning and herbal teas.",
    },
    {
      id: 4,
      name: "Ginger Powder",
      category: "Ginger (dried or powdered)",
      salePrice: 350,
      originalPrice: 450,
      rating: 4.7,
      image: "/path/to/image4.jpg",
      summary: "High-quality ginger powder, excellent for cooking and herbal remedies.",
    },
    {
      id: 5,
      name: "Garlic Powder",
      category: "Garlic Powder",
      salePrice: 320,
      originalPrice: 420,
      rating: 4.8,
      image: "/path/to/image5.jpg",
      summary: "Finely powdered garlic, a must-have spice in every kitchen.",
    },
    {
      id: 6,
      name: "Green Cardamom",
      category: "Cardamom",
      salePrice: 700,
      originalPrice: 850,
      rating: 4.9,
      image: "/path/to/image6.jpg",
      summary: "Aromatic green cardamom pods, ideal for both sweet and savory dishes.",
    },
    {
      id: 7,
      name: "Whole Cloves",
      category: "Cloves",
      salePrice: 600,
      originalPrice: 750,
      rating: 4.6,
      image: "/path/to/image7.jpg",
      summary: "Fragrant whole cloves, used in cooking, baking, and herbal remedies.",
    },
    {
      id: 8,
      name: "Saffron Threads",
      category: "Saffron",
      salePrice: 1500,
      originalPrice: 1800,
      rating: 5.0,
      image: "/path/to/image8.jpg",
      summary: "Pure saffron threads, prized for their unique flavor and color.",
    },
    {
      id: 9,
      name: "Black Peppercorns",
      category: "Black Pepper",
      salePrice: 450,
      originalPrice: 550,
      rating: 4.7,
      image: "/path/to/image9.jpg",
      summary: "Whole black peppercorns, perfect for grinding fresh pepper flavor.",
    },
    {
      id: 10,
      name: "Red Chilli Powder",
      category: "Red Chilli",
      salePrice: 300,
      originalPrice: 400,
      rating: 4.5,
      image: "/path/to/image10.jpg",
      summary: "Hot and flavorful red chilli powder, essential for spicy dishes.",
    },
    {
      id: 11,
      name: "Organic Moringa Powder",
      category: "Moringa Powder",
      salePrice: 500,
      originalPrice: 650,
      rating: 4.8,
      image: "/path/to/image11.jpg",
      summary: "Nutrient-rich moringa powder, great for smoothies and health drinks.",
    },
  ];
  
  export default herbsSpicesData;
  