import React from "react";
import Slider from "react-slick";
import sliderData from "../data/sliderData";
import "../assets/css/test.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const TestPage = () => {
  return (
    <div className="carousel-wrapper-slider">
      <Slider
        dots={true} // Show navigation dots
        infinite={true} // Loop through slides
        speed={500} // Transition speed
        slidesToShow={1} // Number of slides visible at once
        slidesToScroll={1} // Number of slides to scroll at a time
        autoplay={true} // Enable autoplay
        autoplaySpeed={3000} // Autoplay interval
        arrows={true} // Show navigation arrows
      >
        {sliderData.map((slide) => (
          <div key={slide.id} className="carousel-slide">
            <div className="carousel-content">
              <div className="carousel-text">
                <p className="offer-text">{slide.offerText}</p>
                <h2 className="main-heading">{slide.title}</h2>
                <p className="sub-description">{slide.description}</p>
                <a href={slide.link} className="shop-btn">
                  {slide.buttonText}
                </a>
              </div>
              <div className="right-image-container">
                <div className="carousel-image">
                  <img
                    src={slide.image}
                    alt={slide.altText}
                    className="promotion-image"
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default TestPage;
