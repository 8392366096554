const toysData = [
    {
      id: 1,
      name: 'Interactive Alphabet Learning Toy',
      category: 'Educational Toys',
      originalPrice: 1500,
      salePrice: 1200,
      rating: 4.5,
      image: '/images/toys/educational-alphabet.jpg',
      summary: 'Helps kids learn the alphabet in a fun way.',
    },
    {
      id: 2,
      name: 'Colorful Building Block Set',
      category: 'Building Toys',
      originalPrice: 2000,
      salePrice: 1700,
      rating: 4.7,
      image: '/images/toys/building-blocks.jpg',
      summary: 'Boosts creativity and motor skills.',
    },
    {
      id: 3,
      name: 'Creative Art and Craft Kit',
      category: 'Creative Toys',
      originalPrice: 1800,
      salePrice: 1500,
      rating: 4.4,
      image: '/images/toys/art-craft-kit.jpg',
      summary: 'Perfect for budding artists and crafters.',
    },
    {
      id: 4,
      name: 'Kids Outdoor Play Set',
      category: 'Outdoor Toys',
      originalPrice: 2500,
      salePrice: 2200,
      rating: 4.3,
      image: '/images/toys/outdoor-play.jpg',
      summary: 'Encourages physical activity and outdoor fun.',
    },
    {
      id: 5,
      name: 'Doll House with Accessories',
      category: 'Dolls & Accessories',
      originalPrice: 3000,
      salePrice: 2600,
      rating: 4.6,
      image: '/images/toys/doll-house.jpg',
      summary: 'A dream playset for doll lovers.',
    },
    {
      id: 6,
      name: 'Soft Teddy Bear - 15 inches',
      category: 'Soft Toys',
      originalPrice: 1200,
      salePrice: 1000,
      rating: 4.5,
      image: '/images/toys/soft-teddy.jpg',
      summary: 'Super soft and huggable teddy bear.',
    },
    {
      id: 7,
      name: 'Family Board Game',
      category: 'Games & Puzzles',
      originalPrice: 2500,
      salePrice: 2100,
      rating: 4.6,
      image: '/images/toys/board-game.jpg',
      summary: 'Fun for the whole family.',
    },
    {
      id: 8,
      name: 'Superhero Action Figure',
      category: 'Superheroes',
      originalPrice: 1800,
      salePrice: 1500,
      rating: 4.7,
      image: '/images/toys/superhero-figure.jpg',
      summary: 'Detailed action figure of a favorite superhero.',
    },
    {
      id: 9,
      name: 'Cartoon Character Plush Toy',
      category: 'Cartoon Characters',
      originalPrice: 1300,
      salePrice: 1100,
      rating: 4.4,
      image: '/images/toys/cartoon-character.jpg',
      summary: 'Cute plush toy from popular cartoon series.',
    },
    {
      id: 10,
      name: 'Fantasy Creature Figurine',
      category: 'Fantasy & Sci-Fi',
      originalPrice: 2200,
      salePrice: 1800,
      rating: 4.5,
      image: '/images/toys/fantasy-figurine.jpg',
      summary: 'Perfect for imaginative play and storytelling.',
    },
  ];
  
  export default toysData;
  