const edibleOilsData = [
    {
      id: 1,
      name: "Extra Virgin Olive Oil",
      category: "Olive Oil",
      salePrice: 1200,
      originalPrice: 1500,
      rating: 4.8,
      image: "/path/to/image1.jpg",
      summary: "High-quality extra virgin olive oil, perfect for salads and cooking.",
    },
    {
      id: 2,
      name: "Cold-Pressed Coconut Oil",
      category: "Coconut Oil",
      salePrice: 900,
      originalPrice: 1100,
      rating: 4.7,
      image: "/path/to/image2.jpg",
      summary: "Pure cold-pressed coconut oil, ideal for cooking and skincare.",
    },
    {
      id: 3,
      name: "Almond Oil",
      category: "Almond Oil",
      salePrice: 1300,
      originalPrice: 1600,
      rating: 4.6,
      image: "/path/to/image3.jpg",
      summary: "High-quality almond oil, great for cooking and skin health.",
    },
    {
      id: 4,
      name: "Eucalyptus Essential Oil",
      category: "Eucalyptus Essential Oil",
      salePrice: 600,
      originalPrice: 750,
      rating: 4.7,
      image: "/path/to/image4.jpg",
      summary: "Aromatic eucalyptus essential oil, known for its medicinal properties.",
    },
    {
      id: 5,
      name: "Clove Essential Oil",
      category: "Clove Essential Oil",
      salePrice: 800,
      originalPrice: 950,
      rating: 4.8,
      image: "/path/to/image5.jpg",
      summary: "Pure clove essential oil with a strong, warm aroma.",
    },
    {
      id: 6,
      name: "Sandalwood Essential Oil",
      category: "Sandalwood Essential Oil",
      salePrice: 2500,
      originalPrice: 3000,
      rating: 4.9,
      image: "/path/to/image6.jpg",
      summary: "Premium sandalwood essential oil, ideal for aromatherapy.",
    },
    {
      id: 7,
      name: "Refined Olive Oil",
      category: "Olive Oil",
      salePrice: 1000,
      originalPrice: 1200,
      rating: 4.5,
      image: "/path/to/image7.jpg",
      summary: "Refined olive oil, suitable for high-heat cooking.",
    },
    {
      id: 8,
      name: "Organic Coconut Oil",
      category: "Coconut Oil",
      salePrice: 950,
      originalPrice: 1150,
      rating: 4.6,
      image: "/path/to/image8.jpg",
      summary: "Organic coconut oil, great for both culinary and cosmetic use.",
    },
    {
      id: 9,
      name: "Sweet Almond Oil",
      category: "Almond Oil",
      salePrice: 1400,
      originalPrice: 1700,
      rating: 4.7,
      image: "/path/to/image9.jpg",
      summary: "Sweet almond oil, ideal for moisturizing skin and hair.",
    },
    {
      id: 10,
      name: "Therapeutic Grade Eucalyptus Oil",
      category: "Eucalyptus Essential Oil",
      salePrice: 700,
      originalPrice: 850,
      rating: 4.6,
      image: "/path/to/image10.jpg",
      summary: "High-quality therapeutic grade eucalyptus essential oil.",
    }
  ];
  
  export default edibleOilsData;
  