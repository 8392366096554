const menCareData = [
    {
      id: 1,
      name: 'Aloe Shaving Cream - 150ml',
      category: 'Shaving Creams',
      originalPrice: 800,
      salePrice: 600,
      rating: 4.5,
      image: '/images/mencare/shaving-cream.jpg',
      summary: 'Smooth and gentle shaving experience with aloe vera.',
    },
    {
      id: 2,
      name: 'Advanced Whitening Cream for Men',
      category: 'Whitening Creams',
      originalPrice: 1000,
      salePrice: 750,
      rating: 4.3,
      image: '/images/mencare/whitening-cream.jpg',
      summary: 'Brightens and evens out skin tone.',
    },
    {
      id: 3,
      name: 'Anti-Acne Cream - 50g',
      category: 'Acne Creams',
      originalPrice: 900,
      salePrice: 700,
      rating: 4.4,
      image: '/images/mencare/acne-cream.jpg',
      summary: 'Targets acne and reduces inflammation.',
    },
    {
      id: 4,
      name: 'Beard Oil - 30ml',
      category: 'Beard Care for Men',
      originalPrice: 750,
      salePrice: 550,
      rating: 4.6,
      image: '/images/mencare/beard-oil.jpg',
      summary: 'Nourishes and softens beard hair for healthy growth.',
    },
    {
      id: 5,
      name: 'Daily Face Moisturizer',
      category: 'Skincare for Men',
      originalPrice: 850,
      salePrice: 650,
      rating: 4.7,
      image: '/images/mencare/face-moisturizer.jpg',
      summary: 'Hydrating and non-greasy formula for all-day use.',
    },
    {
      id: 6,
      name: 'Charcoal Face Wash',
      category: 'Skincare for Men',
      originalPrice: 650,
      salePrice: 500,
      rating: 4.2,
      image: '/images/mencare/charcoal-facewash.jpg',
      summary: 'Deep cleans pores and removes excess oil.',
    },
    {
      id: 7,
      name: 'Beard Balm - 50g',
      category: 'Beard Care for Men',
      originalPrice: 700,
      salePrice: 550,
      rating: 4.5,
      image: '/images/mencare/beard-balm.jpg',
      summary: 'Shapes and conditions beard with a natural hold.',
    },
    {
      id: 8,
      name: 'Sunscreen for Men - SPF 50',
      category: 'Skincare for Men',
      originalPrice: 950,
      salePrice: 750,
      rating: 4.8,
      image: '/images/mencare/sunscreen.jpg',
      summary: 'Broad spectrum protection from harmful UV rays.',
    },
    {
      id: 9,
      name: 'After Shave Lotion',
      category: 'Shaving Creams',
      originalPrice: 550,
      salePrice: 420,
      rating: 4.4,
      image: '/images/mencare/after-shave.jpg',
      summary: 'Soothes and refreshes skin post-shave.',
    },
    {
      id: 10,
      name: 'Anti-Wrinkle Cream for Men',
      category: 'Skincare for Men',
      originalPrice: 1300,
      salePrice: 1000,
      rating: 4.6,
      image: '/images/mencare/anti-wrinkle-cream.jpg',
      summary: 'Reduces signs of aging and smooths skin.',
    },
  ];
  
  export default menCareData;
  