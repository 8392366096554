const beautyData = [
  {
    id: 1,
    name: 'Whitening Cream - 50ml',
    category: 'Whitening Creams',
    originalPrice: 800,
    salePrice: 600,
    rating: 4.5,
    image: '/images/beauty/whitening-cream.jpg',
    summary: 'Brighten your skin with our advanced whitening cream.',
    brand: 'GlowCare',
    stock: 25,
  },
  {
    id: 2,
    name: 'Nourishing Body Lotion',
    category: 'Lotions',
    originalPrice: 750,
    salePrice: 550,
    rating: 4.3,
    image: '/images/beauty/body-lotion.jpg',
    summary: 'Hydrating lotion for soft and smooth skin.',
    brand: 'PureEssence',
    stock: 40,
  },
  {
    id: 3,
    name: 'Vitamin C Serum - 30ml',
    category: 'Vitamins',
    originalPrice: 1200,
    salePrice: 950,
    rating: 4.7,
    image: '/images/beauty/vitamin-c-serum.jpg',
    summary: 'Boosts skin radiance with vitamin C power.',
    brand: 'SkinVital',
    stock: 30,
  },
  {
    id: 4,
    name: 'Hydrating Facial Serum',
    category: 'Serums',
    originalPrice: 900,
    salePrice: 700,
    rating: 4.6,
    image: '/images/beauty/facial-serum.jpg',
    summary: 'Deep hydration with our natural serum formula.',
    brand: 'NatureGlow',
    stock: 50,
  },
  {
    id: 5,
    name: 'Gentle Facial Cleanser',
    category: 'Cleansers',
    originalPrice: 600,
    salePrice: 450,
    rating: 4.2,
    image: '/images/beauty/facial-cleanser.jpg',
    summary: 'Perfect for sensitive skin, cleanses deeply.',
    brand: 'SoftSkin',
    stock: 45,
  },
  {
    id: 6,
    name: 'Soothing Toner Mist',
    category: 'Toners & Mists',
    originalPrice: 500,
    salePrice: 400,
    rating: 4.4,
    image: '/images/beauty/toner-mist.jpg',
    summary: 'Refreshes and tones your skin with a natural formula.',
    brand: 'FreshMist',
    stock: 35,
  },
  {
    id: 7,
    name: 'Intensive Moisturizer Cream',
    category: 'Moisturizers',
    originalPrice: 700,
    salePrice: 550,
    rating: 4.5,
    image: '/images/beauty/moisturizer-cream.jpg',
    summary: 'Locks in moisture, perfect for dry skin.',
    brand: 'MoistureMax',
    stock: 60,
  },
  {
    id: 8,
    name: 'Exfoliating Scrub - 100ml',
    category: 'Exfoliators & Scrubs',
    originalPrice: 550,
    salePrice: 420,
    rating: 4.3,
    image: '/images/beauty/exfoliating-scrub.jpg',
    summary: 'Gently removes dead skin cells for a fresh look.',
    brand: 'ExfoLite',
    stock: 50,
  },
  {
    id: 9,
    name: 'Anti-Aging Serum - 30ml',
    category: 'Serums',
    originalPrice: 1400,
    salePrice: 1100,
    rating: 4.8,
    image: '/images/beauty/anti-aging-serum.jpg',
    summary: 'Reduces fine lines and promotes skin elasticity.',
    brand: 'YouthRenew',
    stock: 20,
  },
  {
    id: 10,
    name: 'Rejuvenating Night Cream',
    category: 'Whitening Creams',
    originalPrice: 1300,
    salePrice: 1000,
    rating: 4.7,
    image: '/images/beauty/night-cream.jpg',
    summary: 'Revitalizes skin while you sleep for a youthful glow.',
    brand: 'NightGlow',
    stock: 25,
  },
];

export default beautyData;
