import React, { useState, useEffect } from 'react';
import { FaList, FaStar } from "react-icons/fa";
import { MdGridOn, MdFavorite, MdFavoriteBorder } from "react-icons/md";
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from "react-icons/ai";

import { FaCartShopping } from 'react-icons/fa6';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart } from '../../app/actions/actionsCart';
import womenData from '../../data/Clothes/womenData';
import ratingsData from '../../data/ratingsData';
import { addToFavorites, removeFromFavorites } from '../../app/reducers/favoritesSlice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import furnitureCollection from "../../assets/images/products/clothe.webp";
import "../../assets/css/customCategories.css";
import { Link } from 'react-router-dom';

const WomenClothing = () => {
  const [layout, setLayout] = useState(4);
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredProducts, setFilteredProducts] = useState(womenData);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [sortOrder, setSortOrder] = useState('Price, Low To High');
  const [selectedRating, setSelectedRating] = useState(null);
  const [minValue, setMinValue] = useState(700);
  const [maxValue, setMaxValue] = useState(10000);

  const productsPerPage = 12;
  const favorites = useSelector((state) => state.favorites.favorites);
  const cart = useSelector((state) => state.cart.cart);
  const totalPages = Math.ceil(filteredProducts.length / productsPerPage);

  const handleCategoryChange = (category) => {
    setSelectedCategories((prevCategories) =>
      prevCategories.includes(category) ? prevCategories.filter((cat) => cat !== category) : [...prevCategories, category]
    );
  };

  const categoryStockCount = womenData.reduce((acc, product) => {
    if (product.stock > 0) {
      acc[product.category] = (acc[product.category] || 0) + product.stock;
    }
    return acc;
  }, {});

  const handleTypeChange = (type) => {
    setSelectedTypes((prevTypes) =>
      prevTypes.includes(type) ? prevTypes.filter((t) => t !== type) : [...prevTypes, type]
    );
  };

  const handleRatingChange = (rating) => {
    setSelectedRating(rating);
  };

  const handleAddToCart = (product) => {
    if (isInCart(product.id)) {
      toast.info(`${product.name} is already in the cart!`);
    } else {
      dispatch(addToCart(product));
      toast.success(`${product.name} added to the cart!`);
    }
  };

  const isFavorite = (productId) => favorites.some((fav) => fav.id === productId);
  const isInCart = (productId) => cart.some((item) => item.id === productId);

  const handleFavoriteToggle = (product) => {
    if (isFavorite(product.id)) {
      dispatch(removeFromFavorites(product));
      toast.info(`${product.name} removed from favorites!`);
    } else {
      dispatch(addToFavorites(product));
      toast.success(`${product.name} added to favorites!`);
    }
  };

  const showMoreProducts = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePriceRangeChange = (e, type) => {
    const value = Number(e.target.value);
    if (type === 'min') {
      setMinValue(value < maxValue ? value : maxValue - 1);
    } else {
      setMaxValue(value > minValue ? value : minValue + 1);
    }
  };

  const renderStars = (rating) => {
    return (
      <span className="stars">
        {[...Array(5)].map((_, index) => (
          <FaStar key={index} color={index < rating ? "gold" : "grey"} />
        ))}
      </span>
    );
  };

  useEffect(() => {
    const filtered = womenData.filter(
      (product) =>
        (selectedCategories.length === 0 || selectedCategories.includes(product.category)) &&
        (selectedTypes.length === 0 || selectedTypes.includes(product.type)) &&
        product.salePrice >= minValue &&
        product.salePrice <= maxValue &&
        (selectedRating === null || Math.floor(product.rating) === selectedRating)
    );

    const sortedProducts = filtered.sort((a, b) => {
      if (sortOrder === 'Price, Low To High') return a.salePrice - b.salePrice;
      if (sortOrder === 'Price, High To Low') return b.salePrice - a.salePrice;
      if (sortOrder === 'Most Popular') return b.rating - a.rating;
      return 0;
    });

    setFilteredProducts(sortedProducts);
  }, [selectedCategories, selectedTypes, minValue, maxValue, selectedRating, sortOrder]);

  const handleLayoutChange = (value) => {
    if (value === 'grid') setLayout(5);
    else if (value === 'list') setLayout('list');
    else setLayout(value);
  };

  const toggleSidebar = () => {
    setIsSidebarVisible((prev) => !prev);
  };
  const startIndex = (currentPage - 1) * productsPerPage + 1;
  const endIndex = Math.min(currentPage * productsPerPage, filteredProducts.length);
  return (
    <div className="test-page">
      <ToastContainer />
      <div className="Prduct-banner-container">
        <div className="banner-content">
          <h1>Clothes</h1>
          <p><Link to="/">Home</Link> /Clothes Shop</p>
        </div>
      </div>

      <div className="product-category-sidebar-container">
        {isSidebarVisible && (
          <div className="sidebar-container slide-in">
            <span className="sidebar-toggle" onClick={toggleSidebar}>
              < AiOutlineMenuFold size={24} className='sidebarRight-icon' />
            </span>
            <div className="filter-group">
              <h4>Product Categories</h4>
              <div className="women-category">
                <span>Wemen</span>
                <ul>
                  {['Cotton', 'Lawn', 'Cotton Silk', 'Silk', 'Organza', 'Velveta', 'Khaddar', 'Satin', 'Cambric', 'Linen'].map(
                    (category, index) => (
                      <label key={index}>
                        <input
                          type="checkbox"
                          checked={selectedCategories.includes(category)}
                          onChange={() => handleCategoryChange(category)}
                        />
                        {category} ({categoryStockCount[category] || 0})
                      </label>
                    )
                  )}
                </ul>
              </div>
            </div>
            <div className="filter-group">
              <h4>Ready To Wear Type</h4>
              {['2 Piece', '3 Piece'].map((type, index) => (
                <label key={index}>
                  <input
                    type="checkbox"
                    checked={selectedTypes.includes(type)}
                    onChange={() => handleTypeChange(type)}
                  /> {type}
                </label>
              ))}
            </div>
            <div className="filter-group">
              <h4>Price Range (PKR)</h4>
              <div className="price-slider">
                <label>
                  Min Price: ₨{minValue}
                  <input
                    type="range"
                    min="700"
                    max="10000"
                    value={minValue}
                    onChange={(e) => handlePriceRangeChange(e, 'min')}
                  />
                </label>
                <label>
                  Max Price: ₨{maxValue}
                  <input
                    type="range"
                    min="700"
                    max="10000"
                    value={maxValue}
                    onChange={(e) => handlePriceRangeChange(e, 'max')}
                  />
                </label>
              </div>
            </div>
            <div className="filter-group">
              <h4>Filter by Rating</h4>
              {(ratingsData || []).map(({ rating, count }) => (
                <label key={rating} className="rating-option">
                  <input
                    type="radio"
                    value={rating}
                    checked={selectedRating === rating}
                    onChange={() => handleRatingChange(rating)}
                  />
                  <div className="stars">{renderStars(rating)}</div>
                  <span className="rating-count">({count})</span>
                </label>
              ))}
            </div>
          </div>
        )}

        <div className="product-container-main">
          {!isSidebarVisible && (
            <div className="sidebar-toggle-button" onClick={toggleSidebar}>
              <AiOutlineMenuUnfold size={24} />
            </div>
          )}
          <div className="product-navbar-container">
            <div className="product-filter">
              <div className="product-count-display">
                <span>Show Results {startIndex}-{endIndex} of {filteredProducts.length}</span>
              </div>
              <div className="view-options">
                <span className="view-text">VIEW AS:</span>
                {[2, 3, 4].map(num => (
                  <button key={num} className="view-option" onClick={() => handleLayoutChange(num)}>
                    {num}
                  </button>
                ))}
                <button className="view-option" onClick={() => handleLayoutChange('grid')}><MdGridOn /></button>
                <button className="view-option" onClick={() => handleLayoutChange('list')}><FaList /></button>
              </div>
              <div className="sort">
                <span className="sort-text">SORT BY:</span>
                <select
                  className='select-sort'
                  value={sortOrder}
                  onChange={(e) => setSortOrder(e.target.value)}
                >
                  <option value="Best Selling">Best Selling</option>
                  <option value="Price, Low To High">Price Low to High</option>
                  <option value="Price, High To Low">Price High to Low</option>
                </select>
              </div>
            </div>
          </div>

          <div className={`product-layout ${layout === 'list' ? 'product-list' : 'product-grid'}`}>
            <div className={`products-container grid-${layout}`}>
              {(filteredProducts || []).map((product) => (
                <div key={product.id} className={`product ${layout === 'list' ? 'product-list-item' : ''}`}>
                  <span className="sale-tag">SALE {product.discount}</span>
                  <img src={product.image} alt={product.title} className='list-image' />
                  <div className='product-info'>
                    <h4 className="product-title">{product.name}</h4>
                    <p className="product-brand">{product.brand}</p>
                    <div className="price">
                      <span className="old-price">₨{product.originalPrice.toFixed(2)}</span>
                      <span className="new-price"> ₨{product.salePrice.toFixed(2)}</span>
                    </div>
                    <div className="rating">
                      {[...Array(5)].map((_, i) => (
                        <FaStar key={i} color={i < Math.floor(product.rating) ? '#ffc107' : '#e4e5e9'} />
                      ))}
                    </div>
                  </div>
                  <div className="action-buttons action-list-btn">
                    <button
                      className="quick-add-btn quick-add-btn-list"
                      onClick={() => handleAddToCart(product)}
                    >
                      Quick Add <FaCartShopping />
                    </button>
                    <span className="wishlist-icon wishlist-icon-list" onClick={() => handleFavoriteToggle(product)}>
                      {isFavorite(product.id) ? <MdFavorite style={{ color: 'red' }} /> : <MdFavoriteBorder />}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {currentPage < totalPages && (
            <div className="show_more_btn-wrapper" whileHover={{ scale: 1.1 }} transition={{ duration: 0.3 }}>
              <button className="show_more_btn" onClick={showMoreProducts}>
                Show More
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="furniture-collection">
        <div className="collection-image">
          <img src={furnitureCollection} alt="Women Unstitched Clothes Collection" />
        </div>
        <div className="collection-info">
          <h2>New! Women Unstitched Clothes</h2>
          <p>
            Discover our latest collection of unstitched fabrics crafted with elegance and versatility. Perfect for creating custom outfits that reflect your unique style, each piece is designed to bring out your creativity and fashion sense.
          </p>
        </div>
      </div>
    </div>
  );
};

export default WomenClothing;
