const sweetenersData = [
    {
      id: 1,
      name: "Organic Stevia",
      category: "Stevia",
      salePrice: 400,
      originalPrice: 500,
      rating: 4.6,
      image: "/path/to/image1.jpg",
      summary: "A natural, zero-calorie sweetener derived from the stevia plant.",
    },
    {
      id: 2,
      name: "Coconut Sugar",
      category: "Coconut Sugar",
      salePrice: 450,
      originalPrice: 550,
      rating: 4.7,
      image: "/path/to/image2.jpg",
      summary: "A healthier sugar alternative with a caramel-like flavor.",
    },
    {
      id: 3,
      name: "Maple Syrup",
      category: "Maple Syrup",
      salePrice: 800,
      originalPrice: 950,
      rating: 4.8,
      image: "/path/to/image3.jpg",
      summary: "Pure maple syrup with a rich, natural sweetness, ideal for pancakes and desserts.",
    },
    {
      id: 4,
      name: "Stevia Sweetener Packets",
      category: "Stevia",
      salePrice: 420,
      originalPrice: 520,
      rating: 4.5,
      image: "/path/to/image4.jpg",
      summary: "Convenient stevia packets for on-the-go sweetening without calories.",
    },
    {
      id: 5,
      name: "Raw Coconut Sugar",
      category: "Coconut Sugar",
      salePrice: 480,
      originalPrice: 600,
      rating: 4.6,
      image: "/path/to/image5.jpg",
      summary: "Unrefined coconut sugar, perfect for baking and cooking.",
    },
    {
      id: 6,
      name: "Organic Maple Syrup",
      category: "Maple Syrup",
      salePrice: 900,
      originalPrice: 1050,
      rating: 4.9,
      image: "/path/to/image6.jpg",
      summary: "100% organic maple syrup, free from artificial additives and preservatives.",
    },
  ];
  
  export default sweetenersData;
  