const gemStonesData = [
    {
      id: 1,
      name: 'Cubic Zirconia (CZ) - Round Cut',
      category: 'Cubic Zirconia (CZ)',
      originalPrice: 500,
      salePrice: 350,
      rating: 4.4,
      image: '/images/gemstones/cz-round.jpg',
      summary: 'Brilliant round-cut CZ stone for a sparkling finish.',
    },
    {
      id: 2,
      name: 'Natural Sapphire - Oval Cut',
      category: 'Sapphire (Synthetic and Natural)',
      originalPrice: 5000,
      salePrice: 4500,
      rating: 4.7,
      image: '/images/gemstones/sapphire-oval.jpg',
      summary: 'Elegant natural sapphire with deep blue hues.',
    },
    {
      id: 3,
      name: 'Synthetic Ruby - Heart Shape',
      category: 'Ruby (Synthetic and Natural)',
      originalPrice: 3000,
      salePrice: 2600,
      rating: 4.5,
      image: '/images/gemstones/ruby-heart.jpg',
      summary: 'Beautiful heart-shaped synthetic ruby stone.',
    },
    {
      id: 4,
      name: 'Emerald - Square Cut',
      category: 'Emerald (Synthetic and Natural)',
      originalPrice: 6000,
      salePrice: 5200,
      rating: 4.8,
      image: '/images/gemstones/emerald-square.jpg',
      summary: 'Lustrous square-cut emerald for a classic look.',
    },
    {
      id: 5,
      name: 'Topaz - Teardrop Shape',
      category: 'Topaz (Synthetic and Natural)',
      originalPrice: 2500,
      salePrice: 2000,
      rating: 4.6,
      image: '/images/gemstones/topaz-teardrop.jpg',
      summary: 'Radiant topaz stone in a unique teardrop shape.',
    },
    {
      id: 6,
      name: 'Amethyst - Round Cut',
      category: 'Amethyst',
      originalPrice: 1200,
      salePrice: 950,
      rating: 4.3,
      image: '/images/gemstones/amethyst-round.jpg',
      summary: 'Vibrant purple amethyst in a classic round cut.',
    },
    {
      id: 7,
      name: 'Turquoise - Oval Shape',
      category: 'Turquoise',
      originalPrice: 1400,
      salePrice: 1100,
      rating: 4.2,
      image: '/images/gemstones/turquoise-oval.jpg',
      summary: 'Beautiful turquoise stone with natural veins.',
    },
    {
      id: 8,
      name: 'Onyx - Square Cut',
      category: 'Onyx',
      originalPrice: 1000,
      salePrice: 800,
      rating: 4.1,
      image: '/images/gemstones/onyx-square.jpg',
      summary: 'Solid black onyx stone in a square cut.',
    },
    {
      id: 9,
      name: 'Garnet - Marquise Cut',
      category: 'Garnet',
      originalPrice: 1800,
      salePrice: 1500,
      rating: 4.6,
      image: '/images/gemstones/garnet-marquise.jpg',
      summary: 'Deep red garnet in a marquise shape.',
    },
    {
      id: 10,
      name: 'Coral - Cabochon Cut',
      category: 'Coral',
      originalPrice: 2000,
      salePrice: 1700,
      rating: 4.4,
      image: '/images/gemstones/coral-cabochon.jpg',
      summary: 'Natural coral stone in a smooth cabochon finish.',
    },
    {
      id: 11,
      name: 'Moonstone - Oval Cut',
      category: 'Moonstone',
      originalPrice: 2200,
      salePrice: 1900,
      rating: 4.5,
      image: '/images/gemstones/moonstone-oval.jpg',
      summary: 'Iridescent moonstone with a captivating glow.',
    },
    {
      id: 12,
      name: 'Jade - Round Cut',
      category: 'Jade',
      originalPrice: 2600,
      salePrice: 2200,
      rating: 4.7,
      image: '/images/gemstones/jade-round.jpg',
      summary: 'Genuine jade stone in a polished round cut.',
    },
    {
      id: 13,
      name: 'Opal - Freeform Cut',
      category: 'Opal',
      originalPrice: 3200,
      salePrice: 2800,
      rating: 4.8,
      image: '/images/gemstones/opal-freeform.jpg',
      summary: 'Unique opal stone with a freeform cut and brilliant colors.',
    },
    {
      id: 14,
      name: 'Quartz - Rose Quartz Oval',
      category: 'Quartz (Various Types)',
      originalPrice: 1600,
      salePrice: 1300,
      rating: 4.3,
      image: '/images/gemstones/rose-quartz-oval.jpg',
      summary: 'Soothing rose quartz in an elegant oval shape.',
    },
    {
      id: 15,
      name: 'Lapis Lazuli - Square Cut',
      category: 'Lapis Lazuli',
      originalPrice: 2900,
      salePrice: 2500,
      rating: 4.6,
      image: '/images/gemstones/lapis-square.jpg',
      summary: 'Vivid blue lapis lazuli with gold flecks.',
    },
  ];
  
  export default gemStonesData;
  