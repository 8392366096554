import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import CartPage from './pages/Cart';
import CheckoutPage from './pages/CheckoutPage';
import Preloader from './components/Preloader';
import useNavigationLoader from './hooks/useNavigationLoader';
import SignInSignUp from './components/user/SignInSignUp';
import FavouritList from './pages/FavouritList';
import SearchResultsPage from './pages/SearchResultsPage';
import TestPage from './pages/TestPage';
import AllPages from './components/AllPages';
import ChatBot from './components/ChatBot';

const AppContent = () => {
  const loading = useNavigationLoader();
  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/cart" element={<CartPage />} />
            <Route path="/checkout" element={<CheckoutPage />} />
            <Route path="/signIn-signUp" element={<SignInSignUp />} />
            <Route path="/favourit-list" element={<FavouritList />} />
            <Route path="/search" element={<SearchResultsPage />} />
            <Route path="/test_page" element={<TestPage />} />
            <Route path="/*" element={<AllPages />} />
          </Routes>
          <Footer />
          <ChatBot /> {/* Add ChatBot here */}
        </>
      )}
    </>
  );
};

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
