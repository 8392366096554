const desiGheeData = [
    {
      id: 1,
      name: "Organic Desi Ghee",
      category: "Organic Desi Ghee",
      salePrice: 1500,
      originalPrice: 1800,
      rating: 4.8,
      image: "/path/to/image1.jpg",
      summary: "Pure organic desi ghee made from grass-fed cows, rich in flavor and nutrition.",
    },
    {
      id: 2,
      name: "Cow Desi Ghee",
      category: "Cow Desi Ghee",
      salePrice: 1300,
      originalPrice: 1600,
      rating: 4.5,
      image: "/path/to/image2.jpg",
      summary: "Traditional cow desi ghee, perfect for cooking and Ayurvedic purposes.",
    },
    {
      id: 3,
      name: "Buffalo Desi Ghee",
      category: "Buffalo Desi Ghee",
      salePrice: 1200,
      originalPrice: 1500,
      rating: 4.6,
      image: "/path/to/image3.jpg",
      summary: "Rich and creamy buffalo desi ghee, ideal for making sweets and curries.",
    },
    {
      id: 4,
      name: "Grass-fed Desi Ghee",
      category: "Grass-fed Desi Ghee",
      salePrice: 2000,
      originalPrice: 2400,
      rating: 4.9,
      image: "/path/to/image4.jpg",
      summary: "Premium quality desi ghee from grass-fed cows, packed with essential nutrients.",
    },
    {
      id: 5,
      name: "A2 Cow Desi Ghee",
      category: "Cow Desi Ghee",
      salePrice: 1800,
      originalPrice: 2100,
      rating: 4.7,
      image: "/path/to/image5.jpg",
      summary: "A2 cow ghee made from indigenous cow milk, known for its health benefits.",
    },
    {
      id: 6,
      name: "Pure Buffalo Ghee",
      category: "Buffalo Desi Ghee",
      salePrice: 1500,
      originalPrice: 1800,
      rating: 4.6,
      image: "/path/to/image6.jpg",
      summary: "Authentic buffalo ghee with a rich texture and high nutritional value.",
    },
    {
      id: 7,
      name: "Organic Grass-fed Desi Ghee",
      category: "Grass-fed Desi Ghee",
      salePrice: 2200,
      originalPrice: 2600,
      rating: 5.0,
      image: "/path/to/image7.jpg",
      summary: "Certified organic grass-fed ghee, free from additives and preservatives.",
    },
    {
      id: 8,
      name: "Traditional Cow Desi Ghee",
      category: "Cow Desi Ghee",
      salePrice: 1400,
      originalPrice: 1700,
      rating: 4.4,
      image: "/path/to/image8.jpg",
      summary: "Hand-churned cow ghee, made with traditional methods for authentic taste.",
    },
    {
      id: 9,
      name: "Pure Desi Buffalo Ghee",
      category: "Buffalo Desi Ghee",
      salePrice: 1600,
      originalPrice: 1900,
      rating: 4.7,
      image: "/path/to/image9.jpg",
      summary: "High-quality buffalo ghee, great for cooking and enhancing the taste of dishes.",
    },
    {
      id: 10,
      name: "Organic Cow Desi Ghee",
      category: "Organic Desi Ghee",
      salePrice: 1700,
      originalPrice: 2000,
      rating: 4.8,
      image: "/path/to/image10.jpg",
      summary: "Organic cow ghee made from naturally raised cows, ideal for health-conscious users.",
    },
  ];
  
  export default desiGheeData;
  