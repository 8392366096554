const supplementsData = [
    {
      id: 1,
      name: "Turmeric Capsules",
      category: "Turmeric Capsules",
      salePrice: 1200,
      originalPrice: 1500,
      rating: 4.7,
      image: "/path/to/image1.jpg",
      summary: "High-quality turmeric capsules for joint and immune support.",
    },
    {
      id: 2,
      name: "Moringa Capsules",
      category: "Moringa Capsules",
      salePrice: 1000,
      originalPrice: 1300,
      rating: 4.6,
      image: "/path/to/image2.jpg",
      summary: "Organic moringa capsules packed with essential nutrients and antioxidants.",
    },
    {
      id: 3,
      name: "Shilajit Resin",
      category: "Shilajit",
      salePrice: 2500,
      originalPrice: 3000,
      rating: 4.8,
      image: "/path/to/image3.jpg",
      summary: "Pure Shilajit resin, known for its rejuvenating properties.",
    },
    {
      id: 4,
      name: "Ginseng Capsules",
      category: "Ginseng",
      salePrice: 1500,
      originalPrice: 1800,
      rating: 4.7,
      image: "/path/to/image4.jpg",
      summary: "Premium ginseng capsules for energy and vitality.",
    },
    {
      id: 5,
      name: "Omega-3 Fish Oil",
      category: "Omega-3 Supplements",
      salePrice: 1800,
      originalPrice: 2200,
      rating: 4.9,
      image: "/path/to/image5.jpg",
      summary: "Omega-3 fish oil capsules for heart and brain health.",
    },
    {
      id: 6,
      name: "Ashwagandha Capsules",
      category: "Ginseng",
      salePrice: 1300,
      originalPrice: 1600,
      rating: 4.6,
      image: "/path/to/image6.jpg",
      summary: "Ashwagandha capsules to support stress relief and relaxation.",
    },
    {
      id: 7,
      name: "Black Seed Oil Capsules",
      category: "Omega-3 Supplements",
      salePrice: 1400,
      originalPrice: 1700,
      rating: 4.5,
      image: "/path/to/image7.jpg",
      summary: "Black seed oil capsules known for their immune-boosting benefits.",
    },
    {
      id: 8,
      name: "Turmeric and Black Pepper Capsules",
      category: "Turmeric Capsules",
      salePrice: 1250,
      originalPrice: 1550,
      rating: 4.8,
      image: "/path/to/image8.jpg",
      summary: "Enhanced turmeric capsules with black pepper for better absorption.",
    },
    {
      id: 9,
      name: "Moringa Leaf Powder Capsules",
      category: "Moringa Capsules",
      salePrice: 1100,
      originalPrice: 1350,
      rating: 4.7,
      image: "/path/to/image9.jpg",
      summary: "Moringa capsules made from organic moringa leaf powder.",
    },
    {
      id: 10,
      name: "Shilajit Capsules",
      category: "Shilajit",
      salePrice: 2300,
      originalPrice: 2800,
      rating: 4.9,
      image: "/path/to/image10.jpg",
      summary: "Pure Shilajit capsules for strength and vitality.",
    },
  ];
  
  export default supplementsData;
  