import cl1Image from '../../assets/images/products/Clothes/men/Men’s Unstitched Cotton Plain Suit FREE… (1).jpeg';
import cl2Image from '../../assets/images/products/Clothes/men/Men’s Unstitched Cotton Plain Suit FREE… (1).jpeg';
import cl3Image from '../../assets/images/products/Clothes/men/Men’s Unstitched Cotton Plain Suit FREE… (1).jpeg';
import cl4Image from '../../assets/images/products/Clothes/men/Men’s Unstitched Cotton Plain Suit FREE… (1).jpeg';
import cl5Image from '../../assets/images/products/Clothes/men/Men’s Unstitched Cotton Plain Suit FREE… (1).jpeg';
import cl6Image from '../../assets/images/products/Clothes/men/Men’s Unstitched Cotton Plain Suit FREE… (1).jpeg';
import cl7Image from '../../assets/images/products/Clothes/men/Men’s Unstitched Cotton Plain Suit FREE… (1).jpeg';
import cl8Image from '../../assets/images/products/Clothes/men/Men’s Unstitched Cotton Plain Suit FREE… (1).jpeg';
const menData = [
    {
      id: 1,
      name: "Cotton Unstitched Suit Fabric",
      category: "Cotton",
      originalPrice: 1500,
      salePrice: 1200,
      rating: 4.2,
      stock: 25,
      image: cl1Image,
      summary: "Unstitched cotton fabric perfect for creating a casual suit.",
    },
    {
      id: 2,
      name: "Cotton Blends Unstitched Suit Fabric",
      category: "Cotton Blends",
      originalPrice: 1600,
      salePrice: 1300,
      rating: 4.0,
      stock: 30,
      image: cl2Image,
      summary: "Stylish cotton blend unstitched fabric, combining comfort with durability.",
    },
    {
      id: 3,
      name: "Linen Unstitched Suit Fabric",
      category: "Linen",
      originalPrice: 2000,
      salePrice: 1800,
      rating: 4.5,
      stock: 15,
      image: cl3Image,
      summary: "Lightweight linen fabric, ideal for creating a relaxed, customized suit.",
    },
    {
      id: 4,
      name: "Lawn Unstitched Suit Fabric",
      category: "Lawn",
      originalPrice: 1400,
      salePrice: 1200,
      rating: 4.1,
      stock: 20,
      image: cl4Image,
      summary: "Unstitched lawn fabric, perfect for making a casual summer suit.",
    },
    {
      id: 5,
      name: "Khaddar Unstitched Suit Fabric",
      category: "Khaddar",
      originalPrice: 2200,
      salePrice: 1900,
      rating: 4.3,
      stock: 18,
      image: cl5Image,
      summary: "Traditional Khaddar unstitched fabric, ideal for a winter suit.",
    },
    {
      id: 6,
      name: "Karandi Unstitched Suit Fabric",
      category: "Karandi",
      originalPrice: 2500,
      salePrice: 2100,
      rating: 4.6,
      stock: 10,
      image:cl6Image,
      summary: "Premium Karandi unstitched fabric, perfect for formal suits.",
    },
    {
      id: 7,
      name: "Wash & Wear Unstitched Suit Fabric",
      category: "Wash & Wear",
      originalPrice: 1800,
      salePrice: 1500,
      rating: 4.4,
      stock: 22,
      image: cl7Image,
      summary: "Versatile wash & wear unstitched fabric, easy to maintain.",
    },
    {
      id: 8,
      name: "Boski Unstitched Suit Fabric",
      category: "Boski",
      originalPrice: 3000,
      salePrice: 2700,
      rating: 4.8,
      stock: 8,
      image:cl8Image,
      summary: "Luxurious Boski unstitched fabric for an elegant, refined suit.",
    },
    {
      id: 9,
      name: "Cotton Polo Unstitched Suit Fabric",
      category: "Cotton",
      originalPrice: 1600,
      salePrice: 1350,
      rating: 4.1,
      stock: 27,
      image: "path_to_image/cotton_polo_unstitched_suit_fabric.jpg",
      summary: "A stylish cotton unstitched fabric, suitable for a casual suit.",
    },
    {
      id: 10,
      name: "Cotton Blends Jogger Unstitched Suit Fabric",
      category: "Cotton Blends",
      originalPrice: 1700,
      salePrice: 1400,
      rating: 4.3,
      stock: 35,
      image: "path_to_image/cotton_blends_jogger_unstitched_suit_fabric.jpg",
      summary: "Comfortable cotton blend unstitched fabric, great for everyday wear.",
    },
    {
      id: 11,
      name: "Linen Formal Unstitched Suit Fabric",
      category: "Linen",
      originalPrice: 2200,
      salePrice: 1900,
      rating: 4.4,
      stock: 12,
      image: "path_to_image/linen_formal_unstitched_suit_fabric.jpg",
      summary: "Breathable linen fabric, ideal for a formal summer suit.",
    },
    {
      id: 12,
      name: "Lawn Casual Unstitched Suit Fabric",
      category: "Lawn",
      originalPrice: 1300,
      salePrice: 1100,
      rating: 4.0,
      stock: 28,
      image: "path_to_image/lawn_casual_unstitched_suit_fabric.jpg",
      summary: "Light and comfortable lawn fabric, perfect for casual wear.",
    },
    {
      id: 13,
      name: "Khaddar Winter Unstitched Suit Fabric",
      category: "Khaddar",
      originalPrice: 2300,
      salePrice: 2000,
      rating: 4.2,
      stock: 16,
      image: "path_to_image/khaddar_winter_unstitched_suit_fabric.jpg",
      summary: "Warm Khaddar fabric, perfect for winter suits.",
    },
    {
      id: 14,
      name: "Karandi Formal Unstitched Suit Fabric",
      category: "Karandi",
      originalPrice: 2600,
      salePrice: 2200,
      rating: 4.7,
      stock: 9,
      image: "path_to_image/karandi_formal_unstitched_suit_fabric.jpg",
      summary: "A formal Karandi unstitched fabric, perfect for special occasions.",
    },
    {
      id: 15,
      name: "Wash & Wear Blazer Unstitched Suit Fabric",
      category: "Wash & Wear",
      originalPrice: 2400,
      salePrice: 2100,
      rating: 4.5,
      stock: 14,
      image: "path_to_image/wash_wear_blazer_unstitched_suit_fabric.jpg",
      summary: "An easy-care wash & wear fabric, ideal for a blazer suit.",
    },
    {
      id: 16,
      name: "Boski Waistcoat Unstitched Suit Fabric",
      category: "Boski",
      originalPrice: 3100,
      salePrice: 2800,
      rating: 4.9,
      stock: 6,
      image: "path_to_image/boski_waistcoat_unstitched_suit_fabric.jpg",
      summary: "Elegant Boski fabric for a luxurious waistcoat suit.",
    }
  ];
  
  export default menData;
  