const bodyCareData = [
    {
      id: 1,
      name: 'Hydrating Body Lotion - 250ml',
      category: 'Body Lotions & Moisturizers',
      originalPrice: 800,
      salePrice: 600,
      rating: 4.5,
      image: '/images/bodycare/body-lotion.jpg',
      summary: 'Nourishes and hydrates your skin all day.',
    },
    {
      id: 2,
      name: 'Aloe Vera Gel - 150ml',
      category: 'Gel',
      originalPrice: 750,
      salePrice: 550,
      rating: 4.3,
      image: '/images/bodycare/aloe-gel.jpg',
      summary: 'Soothes and cools the skin, perfect for sunburns.',
    },
    {
      id: 3,
      name: 'Rich Body Cream - 200ml',
      category: 'Body Cream',
      originalPrice: 1200,
      salePrice: 950,
      rating: 4.7,
      image: '/images/bodycare/body-cream.jpg',
      summary: 'Luxurious cream for deep skin hydration.',
    },
    {
      id: 4,
      name: 'Refreshing Bath Gel - 300ml',
      category: 'Bath Gel',
      originalPrice: 900,
      salePrice: 700,
      rating: 4.6,
      image: '/images/bodycare/bath-gel.jpg',
      summary: 'Energizing bath gel for a fresh start to your day.',
    },
    {
      id: 5,
      name: 'Hand Sanitizer - 100ml',
      category: 'Sanitizer',
      originalPrice: 600,
      salePrice: 450,
      rating: 4.2,
      image: '/images/bodycare/sanitizer.jpg',
      summary: 'Kills 99.9% of germs instantly.',
    },
    {
      id: 6,
      name: 'Lavender Body Lotion',
      category: 'Body Lotions & Moisturizers',
      originalPrice: 700,
      salePrice: 500,
      rating: 4.4,
      image: '/images/bodycare/lavender-lotion.jpg',
      summary: 'Calming lotion infused with lavender scent.',
    },
    {
      id: 7,
      name: 'Vitamin E Gel',
      category: 'Gel',
      originalPrice: 850,
      salePrice: 680,
      rating: 4.5,
      image: '/images/bodycare/vitamin-e-gel.jpg',
      summary: 'Moisturizing gel rich in Vitamin E for soft skin.',
    },
    {
      id: 8,
      name: 'Shea Butter Body Cream',
      category: 'Body Cream',
      originalPrice: 1100,
      salePrice: 900,
      rating: 4.7,
      image: '/images/bodycare/shea-cream.jpg',
      summary: 'Rich cream with shea butter for intense moisture.',
    },
    {
      id: 9,
      name: 'Citrus Bath Gel',
      category: 'Bath Gel',
      originalPrice: 850,
      salePrice: 650,
      rating: 4.4,
      image: '/images/bodycare/citrus-bath-gel.jpg',
      summary: 'Revitalizing bath gel with citrus extracts.',
    },
    {
      id: 10,
      name: 'Alcohol-Free Hand Sanitizer',
      category: 'Sanitizer',
      originalPrice: 500,
      salePrice: 400,
      rating: 4.3,
      image: '/images/bodycare/alcohol-free-sanitizer.jpg',
      summary: 'Gentle sanitizer for sensitive skin.',
    },
  ];
  
  export default bodyCareData;
  