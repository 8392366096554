const acharChatniData = [
  {
    id: 1,
    name: 'Alu Bukhara Chatni',
    category: 'Alu Bukhara',
    brand: 'Desi Delight',
    image: '/assets/images/products/alu-bukhara-chatni.webp',
    originalPrice: 500,
    salePrice: 450,
    discount: '10%',
    rating: 4.5,
    stock: 15,
  },
  {
    id: 2,
    name: 'Falsa Chatni',
    category: 'Falsa',
    brand: 'Chatpata Foods',
    image: '/assets/images/products/falsa-chatni.webp',
    originalPrice: 600,
    salePrice: 540,
    discount: '10%',
    rating: 4.0,
    stock: 10,
  },
  {
    id: 3,
    name: 'Khubani Achar',
    category: 'Khubani',
    brand: 'Taste Treats',
    image: '/assets/images/products/khubani-achar.webp',
    originalPrice: 700,
    salePrice: 630,
    discount: '10%',
    rating: 3.5,
    stock: 8,
  },
  {
    id: 4,
    name: 'Mix Fruit Chatni',
    category: 'Mix Fruit',
    brand: 'Spice Lovers',
    image: '/assets/images/products/mix-fruit-chatni.webp',
    originalPrice: 750,
    salePrice: 680,
    discount: '9%',
    rating: 4.7,
    stock: 12,
  },
  {
    id: 5,
    name: 'Mango Achar',
    category: 'Mango',
    brand: 'Mango Magic',
    image: '/assets/images/products/mango-achar.webp',
    originalPrice: 800,
    salePrice: 720,
    discount: '10%',
    rating: 4.8,
    stock: 20,
  },
  {
    id: 6,
    name: 'Spicy Chilli Pickle',
    category: 'Chilli',
    brand: 'Spicy Delights',
    image: '/assets/images/products/chilli-pickle.webp',
    originalPrice: 550,
    salePrice: 500,
    discount: '9%',
    rating: 4.3,
    stock: 18,
  },
  {
    id: 7,
    name: 'Garlic Chatni',
    category: 'Garlic',
    brand: 'Herbal Bliss',
    image: '/assets/images/products/garlic-chatni.webp',
    originalPrice: 650,
    salePrice: 600,
    discount: '8%',
    rating: 4.1,
    stock: 9,
  },
  {
    id: 8,
    name: 'Ginger Pickle',
    category: 'Ginger',
    brand: 'Organic Goodies',
    image: '/assets/images/products/ginger-pickle.webp',
    originalPrice: 700,
    salePrice: 650,
    discount: '7%',
    rating: 3.9,
    stock: 7,
  },
  {
    id: 9,
    name: 'Mixed Achar',
    category: 'Mix Achar',
    brand: 'Classic Pickles',
    image: '/assets/images/products/mixed-achar.webp',
    originalPrice: 850,
    salePrice: 800,
    discount: '6%',
    rating: 4.6,
    stock: 6,
  },
  {
    id: 10,
    name: 'Green Mango Achar',
    category: 'Mango',
    brand: 'Mango Magic',
    image: '/assets/images/products/green-mango-achar.webp',
    originalPrice: 900,
    salePrice: 850,
    discount: '5%',
    rating: 4.9,
    stock: 11,
  }
];

export default acharChatniData;
