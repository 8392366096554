import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { clearCart } from '../app/actions/actionsCart';
import '../assets/css/checkout.css';

const Checkout = () => {
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart.cart);

  // Form state
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    permanentAddress: '',
    localAddress: '',
    phone: '',
    paymentMethod: '',
  });

  // Update form state
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Submit handler
  const handleSubmit = (e) => {
    e.preventDefault();

    // Validation (basic)
    if (
      !formData.name ||
      !formData.email ||
      !formData.permanentAddress ||
      !formData.localAddress ||
      !formData.phone ||
      !formData.paymentMethod
    ) {
      alert('Please fill out all fields.');
      return;
    }

    // Process checkout
    console.log('Checkout Data:', { formData, cart });
    alert('Checkout successful!');

    // Clear cart after successful checkout
    dispatch(clearCart());
  };

  return (
    <div className="checkout-container">
      <h1>Checkout</h1>
      <form onSubmit={handleSubmit} className="checkout-form">
        <div className="form-group">
          <label htmlFor="name">Full Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="permanentAddress">Permanent Address:</label>
          <textarea
            id="permanentAddress"
            name="permanentAddress"
            value={formData.permanentAddress}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="localAddress">Local Address:</label>
          <textarea
            id="localAddress"
            name="localAddress"
            value={formData.localAddress}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="phone">Phone Number:</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="paymentMethod">Payment Method:</label>
          <select
            id="paymentMethod"
            name="paymentMethod"
            value={formData.paymentMethod}
            onChange={handleChange}
            required
          >
            <option value="">-- Select Payment Method --</option>
            <option value="Visa">Visa</option>
            <option value="JazzCash">JazzCash</option>
            <option value="EasyPaisa">EasyPaisa</option>
            <option value="MeezanBank">Meezan Bank</option>
            <option value="BankAlfalah">Bank Alfalah</option>
          </select>
        </div>
        <button type="submit" className="checkout-submit">
          Confirm and Pay
        </button>
      </form>

      <div className="checkout-summary">
        <h2>Order Summary</h2>
        {cart.map((item) => (
          <div key={item.id} className="checkout-item">
            <p>
              <strong>{item.name}</strong> (x{item.qty}) - PKR{' '}
              {(item.salePrice || item.price) * item.qty}
            </p>
            <p>Product Code: {item.id}</p>
          </div>
        ))}
        <h3>Total: PKR {cart.reduce((acc, item) => acc + (item.salePrice || item.price) * item.qty, 0)}</h3>
      </div>
    </div>
  );
};

export default Checkout;
