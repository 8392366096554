const watchesGlassesData = [
    {
      id: 1,
      name: 'Classic Analog Watch',
      category: 'Analog Watches',
      originalPrice: 5000,
      salePrice: 4500,
      rating: 4.6,
      image: '/images/watches_glasses/analog-watch.jpg',
      summary: 'A timeless analog watch with a leather strap.',
    },
    {
      id: 2,
      name: 'Digital Sports Watch',
      category: 'Digital Watches',
      originalPrice: 3000,
      salePrice: 2500,
      rating: 4.3,
      image: '/images/watches_glasses/digital-watch.jpg',
      summary: 'A durable digital watch for active lifestyles.',
    },
    {
      id: 3,
      name: 'Smart Watch - Series 5',
      category: 'Smart Watches',
      originalPrice: 15000,
      salePrice: 12000,
      rating: 4.7,
      image: '/images/watches_glasses/smart-watch.jpg',
      summary: 'Stay connected with the latest smart watch features.',
    },
    {
      id: 4,
      name: 'Polarized Sunglasses',
      category: 'Sunglasses',
      originalPrice: 4000,
      salePrice: 3200,
      rating: 4.5,
      image: '/images/watches_glasses/sunglasses.jpg',
      summary: 'Polarized sunglasses for maximum sun protection.',
    },
    {
      id: 5,
      name: 'Prescription Glasses - Round Frame',
      category: 'Prescription Glasses',
      originalPrice: 7000,
      salePrice: 6500,
      rating: 4.4,
      image: '/images/watches_glasses/prescription-glasses.jpg',
      summary: 'Stylish round-frame prescription glasses.',
    },
    {
      id: 6,
      name: 'Reading Glasses - +2.00',
      category: 'Reading Glasses',
      originalPrice: 2000,
      salePrice: 1500,
      rating: 4.2,
      image: '/images/watches_glasses/reading-glasses.jpg',
      summary: 'Lightweight reading glasses with a +2.00 magnification.',
    },
    {
      id: 7,
      name: 'Luxury Analog Watch - Gold',
      category: 'Analog Watches',
      originalPrice: 20000,
      salePrice: 18000,
      rating: 4.8,
      image: '/images/watches_glasses/luxury-analog-watch.jpg',
      summary: 'A luxurious analog watch with a gold finish.',
    },
    {
      id: 8,
      name: 'UV Protection Sunglasses',
      category: 'Sunglasses',
      originalPrice: 3500,
      salePrice: 3000,
      rating: 4.3,
      image: '/images/watches_glasses/uv-sunglasses.jpg',
      summary: 'Sleek UV protection sunglasses for everyday wear.',
    },
    {
      id: 9,
      name: 'Smart Watch with Fitness Tracking',
      category: 'Smart Watches',
      originalPrice: 10000,
      salePrice: 8500,
      rating: 4.6,
      image: '/images/watches_glasses/fitness-smart-watch.jpg',
      summary: 'Track your fitness goals with ease.',
    },
    {
      id: 10,
      name: 'Vintage Round Glasses - Black',
      category: 'Prescription Glasses',
      originalPrice: 6000,
      salePrice: 5200,
      rating: 4.4,
      image: '/images/watches_glasses/vintage-glasses.jpg',
      summary: 'Classic round prescription glasses with a vintage touch.',
    },
  ];
  
  export default watchesGlassesData;
  