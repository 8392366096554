const hairCareData = [
    {
      id: 1,
      name: 'Herbal Shampoo - 500ml',
      category: 'Shampoos',
      originalPrice: 800,
      salePrice: 600,
      rating: 4.5,
      image: '/images/haircare/shampoo.jpg',
      summary: 'Gently cleanses hair with natural ingredients.',
    },
    {
      id: 2,
      name: 'Deep Moisture Conditioner',
      category: 'Conditioners',
      originalPrice: 750,
      salePrice: 550,
      rating: 4.3,
      image: '/images/haircare/conditioner.jpg',
      summary: 'Provides deep conditioning for silky, smooth hair.',
    },
    {
      id: 3,
      name: 'Keratin Hair Treatment - 200ml',
      category: 'Hair Treatments',
      originalPrice: 1200,
      salePrice: 950,
      rating: 4.7,
      image: '/images/haircare/hair-treatment.jpg',
      summary: 'Revitalizes hair with protein-rich treatment.',
    },
    {
      id: 4,
      name: 'Natural Hair Growth Serum',
      category: 'Natural Hair Growth Serums',
      originalPrice: 900,
      salePrice: 700,
      rating: 4.6,
      image: '/images/haircare/growth-serum.jpg',
      summary: 'Stimulates hair growth with natural extracts.',
    },
    {
      id: 5,
      name: 'Organic Coconut Hair Oil',
      category: 'Natural Hair Oils',
      originalPrice: 600,
      salePrice: 450,
      rating: 4.2,
      image: '/images/haircare/hair-oil.jpg',
      summary: 'Nourishes scalp with 100% organic coconut oil.',
    },
    {
      id: 6,
      name: 'Biotin Supplements for Hair',
      category: 'Biotin Supplements',
      originalPrice: 500,
      salePrice: 400,
      rating: 4.4,
      image: '/images/haircare/biotin.jpg',
      summary: 'Enhances hair strength with essential biotin.',
    },
    {
      id: 7,
      name: 'Hair Growth Vitamin Complex',
      category: 'Hair Growth Vitamins',
      originalPrice: 700,
      salePrice: 550,
      rating: 4.5,
      image: '/images/haircare/hair-vitamins.jpg',
      summary: 'Promotes thicker, fuller hair growth.',
    },
    {
      id: 8,
      name: 'Argan Hair Serum - 50ml',
      category: 'Hair Serums & Oils',
      originalPrice: 550,
      salePrice: 420,
      rating: 4.3,
      image: '/images/haircare/argan-serum.jpg',
      summary: 'Smoothes and protects hair with Moroccan argan oil.',
    },
    {
      id: 9,
      name: 'Anti-Dandruff Shampoo',
      category: 'Shampoos',
      originalPrice: 650,
      salePrice: 500,
      rating: 4.1,
      image: '/images/haircare/anti-dandruff.jpg',
      summary: 'Reduces dandruff and soothes scalp irritation.',
    },
    {
      id: 10,
      name: 'Vitamin E Hair Oil',
      category: 'Natural Hair Oils',
      originalPrice: 750,
      salePrice: 600,
      rating: 4.6,
      image: '/images/haircare/vitamin-e-oil.jpg',
      summary: 'Rich in Vitamin E for hair nourishment.',
    },
  ];
  
  export default hairCareData;
  