const healthCareData = [
    {
      id: 1,
      name: 'Daily Multivitamin Tablets',
      category: 'Multivitamins',
      originalPrice: 1500,
      salePrice: 1200,
      rating: 4.5,
      image: '/images/healthcare/multivitamin.jpg',
      summary: 'Provides essential vitamins and minerals for daily health.',
    },
    {
      id: 2,
      name: 'Calcium & Magnesium Capsules',
      category: 'Calcium & Magnesium',
      originalPrice: 2000,
      salePrice: 1700,
      rating: 4.6,
      image: '/images/healthcare/calcium-magnesium.jpg',
      summary: 'Supports bone health with calcium and magnesium.',
    },
    {
      id: 3,
      name: 'Iron Supplement Tablets',
      category: 'Iron Supplements',
      originalPrice: 1300,
      salePrice: 1100,
      rating: 4.3,
      image: '/images/healthcare/iron-supplement.jpg',
      summary: 'Helps combat iron deficiency and boost energy.',
    },
    {
      id: 4,
      name: 'Zinc Immune Booster',
      category: 'Zinc Supplements',
      originalPrice: 900,
      salePrice: 750,
      rating: 4.4,
      image: '/images/healthcare/zinc-supplement.jpg',
      summary: 'Supports immune system health with zinc.',
    },
    {
      id: 5,
      name: 'Ginseng Energy Booster',
      category: 'Ginseng',
      originalPrice: 2500,
      salePrice: 2200,
      rating: 4.7,
      image: '/images/healthcare/ginseng.jpg',
      summary: 'Enhances energy and reduces fatigue naturally.',
    },
    {
      id: 6,
      name: 'Fat Burner Capsules',
      category: 'Fat Burners',
      originalPrice: 3000,
      salePrice: 2700,
      rating: 4.3,
      image: '/images/healthcare/fat-burner.jpg',
      summary: 'Promotes fat metabolism and weight loss.',
    },
    {
      id: 7,
      name: 'Lavender Essential Oil',
      category: 'Essential Oils for Relaxation',
      originalPrice: 1600,
      salePrice: 1400,
      rating: 4.6,
      image: '/images/healthcare/lavender-oil.jpg',
      summary: 'Calming lavender oil for relaxation and stress relief.',
    },
    {
      id: 8,
      name: 'Weight Management Formula',
      category: 'Weight Management',
      originalPrice: 2800,
      salePrice: 2500,
      rating: 4.4,
      image: '/images/healthcare/weight-management.jpg',
      summary: 'Supports weight control and healthy metabolism.',
    },
    {
      id: 9,
      name: 'Vitamin D & Calcium Combo',
      category: 'Calcium & Magnesium',
      originalPrice: 1900,
      salePrice: 1600,
      rating: 4.5,
      image: '/images/healthcare/vitamin-d-calcium.jpg',
      summary: 'Combination of vitamin D and calcium for bone health.',
    },
    {
      id: 10,
      name: 'Omega-3 Fish Oil',
      category: 'Multivitamins',
      originalPrice: 2200,
      salePrice: 2000,
      rating: 4.7,
      image: '/images/healthcare/omega3.jpg',
      summary: 'Promotes heart and brain health with essential Omega-3s.',
    },
  ];
  
  export default healthCareData;
  