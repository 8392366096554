const techHubData = [
    {
      id: 1,
      name: 'Wireless Earbuds',
      category: 'Earphones',
      originalPrice: 3000,
      salePrice: 2500,
      rating: 4.5,
      image: '/images/techhub/earbuds.jpg',
      summary: 'Compact and powerful wireless earbuds with great sound quality.',
    },
    {
      id: 2,
      name: 'Fast Charging Adapter',
      category: 'Chargers',
      originalPrice: 1500,
      salePrice: 1200,
      rating: 4.3,
      image: '/images/techhub/charger.jpg',
      summary: 'Quickly charge your devices with this powerful adapter.',
    },
    {
      id: 3,
      name: 'Durable USB-C Cable',
      category: 'Charging Cables',
      originalPrice: 500,
      salePrice: 400,
      rating: 4.2,
      image: '/images/techhub/charging-cable.jpg',
      summary: 'High-speed USB-C cable for all your charging needs.',
    },
    {
      id: 4,
      name: 'Car Phone Mount',
      category: 'Mounts',
      originalPrice: 1000,
      salePrice: 800,
      rating: 4.1,
      image: '/images/techhub/phone-mount.jpg',
      summary: 'Secure and adjustable car mount for easy navigation.',
    },
    {
      id: 5,
      name: 'Portable Power Bank - 10000mAh',
      category: 'Power Banks',
      originalPrice: 3000,
      salePrice: 2700,
      rating: 4.6,
      image: '/images/techhub/power-bank.jpg',
      summary: 'Stay powered on the go with this high-capacity power bank.',
    },
    {
      id: 6,
      name: 'Bluetooth Selfie Stick',
      category: 'Selfie Sticks',
      originalPrice: 1200,
      salePrice: 1000,
      rating: 4.4,
      image: '/images/techhub/selfie-stick.jpg',
      summary: 'Capture the perfect shot with this extendable selfie stick.',
    },
    {
      id: 7,
      name: '3-Axis Gimbal Stabilizer',
      category: 'Gimbals',
      originalPrice: 8000,
      salePrice: 7000,
      rating: 4.7,
      image: '/images/techhub/gimbal.jpg',
      summary: 'Professional stabilizer for smooth video recording.',
    },
    {
      id: 8,
      name: 'MicroSD Card - 128GB',
      category: 'MicroSD Cards',
      originalPrice: 3000,
      salePrice: 2800,
      rating: 4.5,
      image: '/images/techhub/microsd.jpg',
      summary: 'Expand your storage with this high-speed MicroSD card.',
    },
    {
      id: 9,
      name: 'Laptop Sleeve - 15 inch',
      category: 'Laptop Bags & Sleeves',
      originalPrice: 2500,
      salePrice: 2200,
      rating: 4.6,
      image: '/images/techhub/laptop-sleeve.jpg',
      summary: 'Protective sleeve for your laptop with a sleek design.',
    },
    {
      id: 10,
      name: 'Portable External Hard Drive - 1TB',
      category: 'External Storage',
      originalPrice: 8000,
      salePrice: 7500,
      rating: 4.8,
      image: '/images/techhub/external-storage.jpg',
      summary: 'Reliable external storage solution with fast data transfer.',
    },
  ];
  
  export default techHubData;
  